// @ts-nocheck
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { Context } from 'index';

import {
  addBIDFile,
  deleteBIDFile,
} from 'API/ApplicationAPI';
import { postLegalEntity } from 'API/LegalEntityAPI';
import { check } from 'API/UserAPI';
import { postBlogger } from '../../../../../API/BloggerAPI';

import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import DateElement from 'components/UI/Modals/ModalCreateContract/components/DateElement';
import LawyersInput from 'components/UI/MyInput/LawyersInput';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import SearchBar from 'components/UI/SearchBar/SearchBar';

import { useLogout } from 'hooks/useLogout';
import { useStateNew } from 'hooks/useStateNew';
import { useDate } from '../../../../../hooks/UseDate';
import { useStateNew2 } from '../../../../../hooks/useStateNew2';

import { defaultLegalEntitiesWithId } from 'utils/consts';
import { getSum, getSumRuble, numberToSum } from 'utils/getSums';

import {
  BLOGGER_TYPES,
  CLIENT_TYPES,
  LEGAL_TYPES,
} from '../../../../../utils/consts';

import AccordionArrow from '../../../AccordionArrows/AccordionArrow/AccordionArrow';
import CheckboxElement from '../../../CheckboxItem/CheckboxElement';
import CheckboxItem from '../../../CheckboxItem/CheckboxItem';
import Files from '../../../Files/Files';
import InputElement from '../../../InputElements/InputElement';
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import InputElementInn from '../../../InputElements/InputElementInn';

import PaymentDateNoTime from './PaymentDateNoTime';
import PublicationElement from './PublicationElement';

import classes from '../ModalCreateContract.module.css';

const BloggerElement = React.memo((props) => {
  const {
    index,
    blogger,
    bloggersList,
    allLegalEntity,
    setAllLegalEntity,
    setBloggersList,
    deleteBlogger,
    updateBlogger,
    managerList,
    lawyerList,
    documentList,
    show,
    onHide,
    bidData,
  } = props;

  const { toast } = useContext(Context);
  const [logout] = useLogout();

  const [active, setActive] = useState(false);

  const tooltipText = `<b>Для ИП:</b> ИНН, ОГРНИП, адрес регистрации и банковские реквизиты<br>
  <b>Для СЗ:</b> ИНН, паспортные данные (серия номер паспорта, кем выдан, когда выдан, код подразделения), адрес регистрации и банковские реквизиты<br>
  <b>Для ООО:</b> ИНН, ОГРН, КПП, юридический адрес и банковские реквизиты`;

  const [addBloggerVisible, setAddBloggerVisible] = useState(false);
  const [matches, setMatches] = useState([{}]);

  const [addLegalEntityVisible, setAddLegalEntityVisible] = useState(false);
  const [legalEntityMatches, setLegalEntityMatches] = useState([{}]);

  const [bloggerType, setBloggerType] = useState({
    id: 0,
    label: 'Бренд',
  });
  const [legalEntities, setLegalEntities, updateLegalEntities] = useStateNew2([
    defaultLegalEntitiesWithId,
  ]);

  const [paymentDates, setPaymentDates, changeDate, addDate, deleteDate] = useDate([
    {
      reactId: Date.now(),
      date: null,
      sum: '',
      payment_method: '',
    },
  ]);

  const [bloggerName, setBloggerName, changeBloggerName] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
      contacts: [],
    },
  ]);

  const [socialMediaChannels, setSocialMediaChannels] = useState([
    {
      tempId: Date.now(),
      link: '',
      publication: [],
      is_assignment: false,
      assignment_type: { id: 0, name: 'Отчуждение' },
      assignment_comment: '',
      is_repost: false,
      repost_comment: '',
    },
  ]);

  const [files, setFiles] = useState([]);

  const [periodDates, setPeriodDates, changePeriodDate] = useStateNew2([
    { id: Date.now(), date_start: null, date_end: null },
  ]);

  const [bloggerLegalEntity, setBloggerLegalEntity, changeBloggerLegalEntity] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [
    selectedOurLegalEntity,
    setSelectedOurLegalEntity,
    changeSelectedOurLegalEntity,
  ] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [responsibleLawyer, setResponsibleLawyer, changeResponsibleLawyer] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsibleDocument,
    setResponsibleDocument,
    changeResponsibleDocument,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [
    responsible,
    setResponsible,
    changeResponsible,
    addResponsible,,
    checkResponsible,
    deleteResponsible,
  ] = useStateNew([{ fieldId: Date.now(), id: null, name: '' }]);

  const [contacts, setContacts, , , deleteContact] = useStateNew2([
    { id: Date.now(), email: '' },
  ]);

  const [
    additionalContacts,
    setAdditionalContacts,
    changeAdditionalContact,
    addAdditionalContact,
    deleteAdditionalContact,
  ] = useStateNew2([{ id: Date.now(), email: '' }]);

  const createBloggerHandler = () => {
    const data = {
      type: { id: bloggerType.id },
      name: bloggerName[0].name,
      contacts: [],
    };

    check()
      .then(() => {
        postBlogger(data)
          .then((response) => {
            toast.setShowSuccess(true);
            toast.setToastInfo('Новый исполнитель создан');
            setBloggerName([
              {
                fieldId: Date.now(),
                id: response.id,
                name: response.name,
                contacts: response.contacts,
              },
            ]);

            setBloggersList([
              ...bloggersList,
              {
                id: response.id,
                name: response.name,
                fullname: response.fullname,
                contacts: response.contacts,
                type: response.type,
              },
            ]);

            setAddBloggerVisible(false);
            setMatches([{}]);
          })
          .catch((error) => {
            if (error.response.data.contacts) {
              toast.setToastInfo('Введите правильный email');
              toast.setShowError(true);
            } else if (error.response.data.name) {
              toast.setToastInfo('Исполнитель с таким ником уже создан');
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            }
          });
      })
      .catch((error) => logout(error));
  };

  const closeCreateLegalEntityHandler = () => {
    setAddLegalEntityVisible(false);
    setLegalEntityMatches([{}]);
    setLegalEntities([defaultLegalEntitiesWithId]);
  };

  const createLegalEntityHandler = () => {
    let data = {};

    if (legalEntities[0].legal_name) {
      data = {
        type: { id: legalEntities[0].type.id },
        legal_name: legalEntities[0].legal_name,
        inn: legalEntities[0].inn,
        file_links: legalEntities[0].file_links,
        bank_details: [],
      };
    }

    check()
      .then(() => {
        postLegalEntity(data)
          .then((response) => {
            toast.setShowSuccess(true);
            toast.setToastInfo('Новое ЮЛ создано');
            setBloggerLegalEntity([
              {
                fieldId: Date.now(),
                id: response.id,
                name: response.legal_name,
              },
            ]);

            setAllLegalEntity([
              ...allLegalEntity,
              {
                id: response.id,
                legal_name: response.legal_name,
              },
            ]);

            setAddLegalEntityVisible(false);
            setLegalEntityMatches([{}]);
            setLegalEntities([defaultLegalEntitiesWithId]);
          })
          .catch((error) => {
            if (error.response.data.contacts) {
              toast.setToastInfo('Введите правильный email');
              toast.setShowError(true);
            } else if (error.response.data.type) {
              toast.setToastInfo('Выберите форму ЮЛ');
              toast.setShowError(true);
            } else if (error?.response?.data[0]) {
              toast.setToastInfo(error.response.data[0]);
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Обратитесь в техподдержку');
              toast.setShowError(true);
            }
          });
      })
      .catch((error) => logout(error));
  };

  const addSocialMediaChannels = () => {
    setSocialMediaChannels([
      ...socialMediaChannels,
      {
        tempId: Date.now(),
        link: '',
        publication: [],
        is_assignment: false,
        assignment_type: { id: 0, name: 'Отчуждение' },
        assignment_comment: '',
        is_repost: false,
        repost_comment: '',
      },
    ]);
  };

  // Проверяем наличие id или tempId, первый прилетает с сервера
  // Второй создается при создании публикации
  const setSocialMediaElement = (pubId, data) => {
    setSocialMediaChannels(
      socialMediaChannels.map((element) => (element.tempId
        ? element.tempId === pubId
          ? {
            ...element,
            ...data,
          }
          : element
        : element.id === pubId
          ? {
            ...element,
            ...data,
          }
          : element)),
    );
  };

  const deleteSocialMediaChannel = (pubId) => {
    if (socialMediaChannels.length > 1) {
      let flag = false;
      setSocialMediaChannels(
        socialMediaChannels.filter((element) => (element.tempId ? element.tempId !== pubId : element.id !== pubId)),
      );

      socialMediaChannels.forEach((element) => {
        if (element.id === pubId) {
          flag = true;
        }
      });
    }
  };

  useEffect(() => {
    updateBlogger(blogger, 'social_media_channels', socialMediaChannels);
  }, [socialMediaChannels]);

  useEffect(() => {
    updateBlogger(blogger, 'client_legal_entity', selectedOurLegalEntity[0]);
  }, [selectedOurLegalEntity]);

  useEffect(() => {
    updateBlogger(blogger, 'contractor_legal_entity', bloggerLegalEntity[0]);
  }, [bloggerLegalEntity]);

  useEffect(() => {
    updateBlogger(blogger, 'placement_period', periodDates[0]);
  }, [periodDates]);

  useEffect(() => {
    updateBlogger(blogger, 'lawyer', responsibleLawyer);
  }, [responsibleLawyer]);

  useEffect(() => {
    updateBlogger(blogger, 'doc_manager', responsibleDocument);
  }, [responsibleDocument]);

  useEffect(() => {
    updateBlogger(blogger, 'staff_contacts', responsible);
  }, [responsible]);

  useEffect(() => {
    updateBlogger(blogger, 'blogger_contacts', contacts);
  }, [contacts]);

  useEffect(() => {
    updateBlogger(blogger, 'addition_emails', additionalContacts);
  }, [additionalContacts]);

  useEffect(() => {
    updateBlogger(blogger, 'blogger', bloggerName);

    const isFounded = bloggerName[0].contacts.filter(({ id: id1 }) => contacts.some(({ id: id2 }) => id2 === id1));

    if (isFounded.length > 0) {
      return;
    }

    if (bloggerName[0].contacts.length > 0) {
      setContacts(bloggerName[0].contacts);
    } else {
      setContacts([]);
    }
  }, [bloggerName[0]]);

  // Обновления состояния при открытии заявки
  useEffect(() => {
    if (show) {
      setAddBloggerVisible(false);

      if (blogger.blogger[0].id) {
        setBloggerName([{ ...blogger.blogger[0], fieldId: Date.now() }]);
      }

      if (blogger.social_media_channels.length > 0) {
        setSocialMediaChannels(blogger.social_media_channels);
      }

      if (blogger.placement_period) {
        setPeriodDates([blogger.placement_period]);
      }

      if (blogger?.contractor_legal_entity?.id > 0) {
        setBloggerLegalEntity([
          {
            fieldId: Date.now(),
            id: blogger?.contractor_legal_entity?.id,
            name:
              blogger?.contractor_legal_entity?.legal_name
              || blogger?.contractor_legal_entity?.name,
          },
        ]);
      }

      if (!blogger.is_contract) {
        setSelectedOurLegalEntity([
          {
            fieldId: Date.now(),
            id: 'customId',
            name: 'Физическая оплата',
          },
        ]);
      } else if (blogger?.client_legal_entity?.id > 0) {
        setSelectedOurLegalEntity([
          {
            fieldId: Date.now(),
            id: blogger?.client_legal_entity?.id,
            name:
              blogger?.client_legal_entity?.legal_name
              || blogger?.client_legal_entity?.name,
          },
        ]);
      }

      if (blogger.payment.payment_dates.length > 0) {
        setPaymentDates(blogger.payment.payment_dates);
      }

      if (blogger.lawyer.length > 0) {
        blogger.lawyer.forEach((element) => {
          setResponsibleLawyer([
            {
              fieldId: Date.now(),
              id: element.id,
              name: element.name || element.fullname,
            },
          ]);
        });
      }

      if (blogger.doc_manager.length > 0) {
        blogger.doc_manager.forEach((element) => {
          setResponsibleDocument([
            {
              fieldId: Date.now(),
              id: element.id,
              name: element.name || element.fullname,
            },
          ]);
        });
      }

      if (blogger.blogger_contacts.length > 0) {
        setContacts(blogger.blogger_contacts);
      }

      if (blogger.addition_emails.length > 0) {
        setAdditionalContacts(blogger.addition_emails);
      } else {
        setAdditionalContacts([]);
      }

      setResponsible(blogger.staff_contacts);

      setFiles(blogger.files);
    }
  }, [bidData, show]);

  useEffect(() => {
    if (bloggerName[0]?.name?.length > 0) {
      setMatches(
        bloggersList?.filter((element) => {
          const regex = new RegExp(`${bloggerName[0].name}`, 'gi');
          return element?.name?.match(regex) || element?.fullname?.match(regex);
        }),
      );
    }
  }, [bloggerName[0]?.name]);

  useEffect(() => {
    if (bloggerLegalEntity[0]?.name?.length > 0) {
      setLegalEntityMatches(
        allLegalEntity.filter((element) => {
          const regex = new RegExp(`${bloggerLegalEntity[0].name}`, 'gi');
          return element?.legal_name?.match(regex);
        }),
      );
    } else {
      setLegalEntityMatches([{}]);
    }
  }, [bloggerLegalEntity[0]?.name]);

  useEffect(() => {
    if (blogger?.is_contract === false) {
      setBloggerLegalEntity([
        {
          fieldId: Date.now(),
          id: 'customId',
          name: blogger?.blogger[0]?.name,
        },
      ]);

      setSelectedOurLegalEntity([
        {
          id: 'customId',
          name: 'Физическая оплата',
          tax: null,
        },
      ]);

      setAddLegalEntityVisible(false);
      setLegalEntityMatches([{}]);
      setLegalEntities([defaultLegalEntitiesWithId]);
    } else if (bloggerLegalEntity[0].id === 'customId') {
      setBloggerLegalEntity([
        {
          fieldId: Date.now(),
          id: null,
          name: '',
        },
      ]);

      setSelectedOurLegalEntity([
        {
          fieldId: Date.now(),
          id: null,
          name: '',
        },
      ]);
      setAddLegalEntityVisible(false);
    }
  }, [blogger.is_contract]);

  const addFileHandler = (event, currentBlogger) => {
    if (event?.target?.files?.length > 0) {
      const formData = new FormData();
      Array.from(event.target.files).forEach((file) => {
        formData.append(file.name, file);
      });

      if (currentBlogger.is_draft) {
        formData.append('draft_id', currentBlogger.draft_id);
      } else {
        formData.append('contract_id', currentBlogger.id);
        formData.append('draft_id', currentBlogger.draft_id);
      }

      check().then(() => {
        addBIDFile(formData).then((filesData) => {
          setFiles(filesData.data);
        });
      });
    }
  };

  const deleteFileHandler = (id) => {
    check()
      .then(() => {
        deleteBIDFile(id).then((filesData) => {
          setFiles(filesData.data);
        });
      })
      .catch(() => {
        logout();
        onHide();
      });
  };

  // Расчеты
  const [priceWithNds, setPriceWithNds] = useState('');
  const [priceWithoutNds, setPriceWithoutNds] = useState('');

  const updatePayment = (fieldName, value) => {
    const payment = { ...blogger.payment, [fieldName]: value };
    updateBlogger(blogger, 'payment', payment);
  };

  useMemo(() => {
    if (blogger.payment.is_nds) {
      setPriceWithoutNds(getSum(blogger.payment?.total_sum) / 1.2);

      setPriceWithNds(
        (getSum(blogger.payment?.total_sum) * 20) / 120,
      );
    }
  }, [blogger.payment?.total_sum, blogger.payment.is_nds]);

  useEffect(() => {
    const payment = { ...blogger.payment, nds_sum: priceWithNds };

    updateBlogger(blogger, 'payment', payment);
  }, [priceWithNds]);

  useEffect(() => {
    const payment = { ...blogger.payment, payment_dates: paymentDates };
    updateBlogger(blogger, 'payment', payment);
  }, [paymentDates]);

  let publicationNumber = 1;
  const publicationList = [];

  socialMediaChannels.map((social_media, social_media_index) => social_media.publication.map((publication, publicationIndex) => publicationList.push({
    social_media_index,
    publicationIndex,
    publicationNumber: publicationNumber++,
  })));

  return (
    <div className={classes.bloggerElement__container}>
      <div
        className={classes.blogger__header}
        onClick={() => setActive(!active)}
      >
        <span>{bloggerName[0].id ? bloggerName[0].name : 'Исполнитель'}</span>

        <div className={classes.blogger__header__item}>
          <SuccessBtn
            text={`Удалить Исполнителя ${index + 1}`}
            type="white"
            style={{
              width: 188,
              padding: '2px 10px',
              fontSize: '12px',
            }}
            onClick={(event) => deleteBlogger(blogger, event)}
          />

          <AccordionArrow isActive={active} />
        </div>
      </div>

      <CSSTransition
        in={active}
        timeout={600}
        classNames="application"
        appear
        unmountOnExit
      >
        <div>
          <ContractElement text="Исполнитель*">
            <div className={classes.ourСontacts__container}>
              <SearchBar
                text={bloggerName[0]}
                setText={changeBloggerName}
                list={bloggersList}
                placeholder="Имя"
                type="bloggerApplication"
                required
              />

              {matches.length === 0
                && !bloggerName[0].id
                && !addBloggerVisible && (
                  <div className={classes.addButton}>
                    <NewAddBtn
                      text="Добавить исполнителя"
                      onClick={() => setAddBloggerVisible(true)}
                    />
                  </div>
              )}
            </div>
          </ContractElement>

          {addBloggerVisible && (
            <div className={classes.addBlogger__container}>
              <InputElement
                text="Тип"
                value={bloggerType}
                setValue={(type) => setBloggerType(type)}
                dropdown={[...CLIENT_TYPES.LIST, ...BLOGGER_TYPES.LIST]}
              />

              <SuccessBtn
                text="Создать исполнителя"
                type="white"
                btnType="button"
                className={classes.addBlogger__btn}
                onClick={() => createBloggerHandler()}
              />
            </div>
          )}

          {socialMediaChannels?.map((publication, index) => (
            <PublicationElement
              key={publication.id || publication.tempId}
              index={index}
              publication={publication}
              setPublicationElement={setSocialMediaElement}
              deletePlatform={deleteSocialMediaChannel}
              dataProps={bidData}
              show={show}
              blogger={bloggerName[0].name}
              publicationList={publicationList}
              platformIndex={index}
            />
          ))}

          <div className={classes.addPublication}>
            <NewAddBtn
              text="Добавить ссылку на социальную сеть"
              onClick={() => addSocialMediaChannels()}
            />
          </div>

          <InputElement
            text="KPI"
            placeholder="Введите текст"
            value={blogger.kpi}
            onChange={(event) => updateBlogger(blogger, 'kpi', event.target.value)}
          />

          <DateElement
            value1={periodDates[0].date_start}
            value2={periodDates[0].date_end}
            onChange1={(event) => changePeriodDate(periodDates[0].id, 'date_start', event)}
            onChange2={(event) => changePeriodDate(periodDates[0].id, 'date_end', event)}
            type="date"
            text="Период размещения контента"
            style={{ margin: '10px 0 10px' }}
            inner_style={{ width: 'auto' }}
          />

          <InputElement
            text="Комментарий"
            placeholder="Комментарий к периоду размещения контента"
            value={blogger.period_comment}
            onChange={(event) => updateBlogger(blogger, 'period_comment', event.target.value)}
          />

          <ContractElement
            text="Эксклюзив"
            style={{ alignItems: 'start' }}
            textStyle={{ height: 32 }}
          >
            <div className={classes.ourСontacts__container}>
              <CheckboxElement style={{ margin: 0 }}>
                <CheckboxItem
                  text="Да"
                  status={blogger.is_exclusive}
                  setStatus={() => updateBlogger(blogger, 'is_exclusive', true)}
                />

                <CheckboxItem
                  text="Нет"
                  status={!blogger.is_exclusive}
                  setStatus={() => updateBlogger(blogger, 'is_exclusive', false)}
                />
              </CheckboxElement>

              {blogger.is_exclusive && (
                <LawyersInput
                  type="text"
                  value={blogger.exclusive_comment}
                  onChange={(event) => updateBlogger(
                    blogger,
                    'exclusive_comment',
                    event.target.value,
                  )}
                  placeholder="Введите текст..."
                />
              )}
            </div>
          </ContractElement>

          <InputElement
            text="Общий комментарий"
            placeholder="Введите текст..."
            textarea
            value={blogger.comment}
            onChange={(event) => updateBlogger(blogger, 'comment', event.target.value)}
            textareaStyle={{ maxHeight: '90px', height: '90px' }}
            elementClassName={classes.comment__container}
          />

          <ContractElement text="Договор" style={{ alignItems: 'start' }}>
            <CheckboxElement style={{ margin: 0 }}>
              <CheckboxItem
                text="Да"
                status={blogger.is_contract}
                setStatus={() => updateBlogger(blogger, 'is_contract', true)}
              />

              <CheckboxItem
                text="Нет"
                status={!blogger.is_contract}
                setStatus={() => updateBlogger(blogger, 'is_contract', false)}
              />
            </CheckboxElement>
          </ContractElement>

          <ContractElement text="ЮЛ заказчика*" style={{ alignItems: 'center' }}>
            <div className={classes.ourСontacts__container}>
              <SearchBar
                text={selectedOurLegalEntity[0]}
                setText={changeSelectedOurLegalEntity}
                list={allLegalEntity}
                type="legal_details"
                disabled={blogger.is_contract === false}
              />
            </div>
          </ContractElement>

          <ContractElement
            text="ЮЛ исполнителя*"
            style={{ alignItems: 'center' }}
          >
            <div className={classes.ourСontacts__container}>
              <SearchBar
                text={bloggerLegalEntity[0]}
                setText={changeBloggerLegalEntity}
                list={allLegalEntity}
                type="legal_details"
                disabled={blogger.is_contract === false}
              />

              {legalEntityMatches.length === 0
                && !bloggerLegalEntity[0].id
                && !addLegalEntityVisible && (
                  <div className={classes.addButton}>
                    <NewAddBtn
                      text="Добавить ЮЛ"
                      onClick={() => {
                        updateLegalEntities(
                          legalEntities[0].id,
                          'legal_name',
                          bloggerLegalEntity[0].name,
                        );
                        setBloggerLegalEntity([
                          { ...bloggerLegalEntity[0], name: '' },
                        ]);
                        setAddLegalEntityVisible(true);
                      }}
                    />
                  </div>
              )}
            </div>
          </ContractElement>

          {addLegalEntityVisible && (
            <div className={classes.addBlogger__container}>
              <InputElement
                text="Юридическое лицо"
                placeholder="Введите текст"
                value={legalEntities[0]?.legal_name}
                onChange={(event) => updateLegalEntities(
                  legalEntities[0].id,
                  'legal_name',
                  event.target.value,
                )}
              />

              <InputElement
                text="Форма ЮЛ"
                placeholder="Введите текст"
                value={legalEntities[0]?.type}
                setValue={(value) => updateLegalEntities(legalEntities[0].id, 'type', value)}
                dropdown={LEGAL_TYPES.LIST}
              />

              <InputElementInn
                text="ИНН"
                element={legalEntities[0]}
                setLegalEntities={setLegalEntities}
                legalEntities={legalEntities}
                value={legalEntities[0]?.inn}
                onChange={(value) => updateLegalEntities(legalEntities[0].id, 'inn', value)}
              />
              <div className={classes.note}>
                <div>
                  *При вводе ИНН наименование ЮЛ будет заполнено автоматически
                  (Кроме СЗ и иностранных ЮЛ)
                </div>
              </div>

              <InputElement
                text="Ссылка на документы"
                placeholder="Введите текст"
                value={legalEntities[0]?.file_links}
                onChange={(event) => updateLegalEntities(
                  legalEntities[0].id,
                  'file_links',
                  event.target.value,
                )}
                tooltipText={tooltipText}
              />

              <div className={classes.addLegalEntity__btn__container}>
                <SuccessBtn
                  text="Создать"
                  btnType="button"
                  className={classes.addLegalEntity__btn}
                  onClick={() => createLegalEntityHandler()}
                />

                <SuccessBtn
                  text="Отменить"
                  type="white"
                  btnType="button"
                  className={classes.addLegalEntity__btn}
                  onClick={() => closeCreateLegalEntityHandler()}
                />
              </div>
            </div>
          )}

          <InputElement
            type="number"
            text="Сумма договора с исполнителем*"
            value={getSumRuble(blogger.payment?.total_sum)}
            onChange={(event) => updatePayment('total_sum', getSumRuble(event.target.value))}
            placeholder="0,00 Р"
          />

          {blogger.is_contract && (
            <CheckboxElement
              text="НДС*"
              style={{ margin: '10px 0' }}
              innerStyle={{ width: 'auto' }}
            >
              <CheckboxItem
                text="Да"
                status={blogger.payment.is_nds}
                setStatus={() => updatePayment('is_nds', true)}
              />
              <CheckboxItem
                text="Нет"
                status={!blogger.payment.is_nds}
                setStatus={() => updatePayment('is_nds', false)}
              />
            </CheckboxElement>
          )}

          {blogger.payment.is_nds && (
            <>
              <InputElement
                type="number"
                text="Сумма НДС (20%)"
                placeholder="0,00 Р"
                value={numberToSum(priceWithNds)}
                disabled
              />
              <InputElement
                type="number"
                text="Стоимость без НДС"
                placeholder="0,00 Р"
                value={numberToSum(priceWithoutNds)}
                disabled
              />
            </>
          )}

          {paymentDates?.map((date, index) => (
            <div key={date.reactId}>
              {!blogger.is_contract && (
                <InputElement
                  text="Способ оплаты"
                  placeholder="ФИО / Номер карты..."
                  value={date.payment_method}
                  onChange={(event) => changeDate(
                    date.id ? date.id : date.reactId,
                    'payment_method',
                    event.target.value,
                  )}
                />
              )}

              <ContractElement
                text={
                  (index === 0 || !blogger.is_contract)
                  && 'Порядок оплаты исполнителю*'
                }
              >
                <PaymentDateNoTime
                  key={date.reactId}
                  element={date}
                  deleteDate={deleteDate}
                  changeDate={changeDate}
                  type="Step3"
                  blogger={blogger}
                />
              </ContractElement>
            </div>
          ))}

          <div className={classes.addPublication}>
            <div className={classes.note__container}>
              <div className={classes.first_note__container}>
                ОБРАТИТЬ ВНИМАНИЕ!
              </div>
              <span className={classes.second_note__container}>
                Дата оплаты должна соответствовать платежным дням - ПН, СР, ПТ
              </span>
            </div>

            <NewAddBtn
              text="Добавить оплату"
              onClick={() => addDate(
                {
                  reactId: Date.now(),
                  date: null,
                  sum: '',
                  payment_method: paymentDates[0].payment_method,
                },
                true,
              )}
            />
          </div>

          <ContractElement
            text="Контактные лица с нашей стороны*"
            style={{ alignItems: 'start', marginBottom: 20 }}
          >
            <div className={classes.ourСontacts__container}>
              {responsibleLawyer?.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={lawyerList}
                  text={element}
                  setText={changeResponsibleLawyer}
                  placeholder="Юрист"
                  required
                  type="staff"
                />
              ))}

              {responsibleDocument?.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={documentList}
                  text={element}
                  setText={changeResponsibleDocument}
                  placeholder="ДОК"
                  required
                  type="staff"
                />
              ))}

              <InputElement
                value={blogger.manager[0].fullname}
                placeholder=""
                style={{ margin: 0 }}
                disabled
              />

              {responsible?.map((element) => (
                <SearchBar
                  key={element.fieldId}
                  list={managerList}
                  text={element}
                  setText={changeResponsible}
                  placeholder=""
                  checkResponsible={checkResponsible}
                  deleteElement={deleteResponsible}
                  required
                  type="staff"
                />
              ))}

              <NewAddBtn
                text="Добавить контакт"
                onClick={() => addResponsible()}
              />
            </div>
          </ContractElement>

          {contacts.length > 0 && (
            <ContractElement
              text="Контакты исполнителя"
              style={{ alignItems: 'start' }}
            >
              <div className={classes.ourСontacts__container}>
                {contacts?.map((contact) => (
                  <InputElement
                    key={contact.id}
                    type="email"
                    value={contact.email}
                    style={{ margin: 0 }}
                    deleteHandler={() => deleteContact(contact.id, true)}
                    placeholder="Почта из карточки исполнителя"
                    cancelBtn
                    disabled
                  />
                ))}
              </div>
            </ContractElement>
          )}

          <ContractElement
            text="Дополнительные контакты "
            style={{
              alignItems: additionalContacts.length ? 'start' : 'center',
              marginBottom: 20,
            }}
          >
            <div className={classes.ourСontacts__container}>
              {additionalContacts?.map((contact) => (
                <InputElement
                  key={contact.id}
                  type="email"
                  value={contact.email}
                  onChange={(event) => changeAdditionalContact(
                    contact.id,
                    'email',
                    event.target.value,
                  )}
                  style={{ margin: 0 }}
                  deleteHandler={() => deleteAdditionalContact(contact.id, true)}
                  placeholder="Введите почту..."
                  cancelBtn
                />
              ))}

              <NewAddBtn
                text="Добавить контакт"
                onClick={() => addAdditionalContact()}
              />
            </div>
          </ContractElement>

          <ContractElement text="Прикрепить файл к договору исполнителя">
            <div className={classes.ourСontacts__container}>
              {files?.length > 0 && (
                <div className={classes.files}>
                  {files?.map((file) => (
                    <Files
                      key={file.id}
                      file={file}
                      deleteFileHandler={() => deleteFileHandler(file.id)}
                      setFiles={setFiles}
                    />
                  ))}
                </div>
              )}

              <MyInputFile
                onChange={(event) => addFileHandler(event, blogger)}
                multiple
                htmlFor={`blogger_element_${blogger.draft_id}`}
              />
            </div>
          </ContractElement>
        </div>
      </CSSTransition>
    </div>
  );
});

export default BloggerElement;
