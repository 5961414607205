// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  getBid, getSKBid, postSKBid,
} from 'API/ApplicationAPI';
import { getClients } from 'API/ClientAPI';
import { autocompleteLegalEntities } from 'API/LawyersAPI/AutocompleteAPI';
import {
  fetchDepartments,
  fetchStaff,
  fetchTeams,
} from 'API/ManagerAPI/AutocompleteAPI';
import {
  deleteProject,
  deleteProjectFile,
  fetchOneProject,
  fetchOneProjectEstimate,
  fetchOneProjectMargin,
  fetchOneProjectPublications,
  fetchOneProjectTasks,
  getComments,
  getHistory,
  patchOneProject,
  patchOneProjectPublication,
  postComment,
  updateOneProjectEstimate,
  updateOneProjectMargin,
  uploadProjectFile,
} from 'API/ManagerAPI/ProjectAPI';
import { check } from 'API/UserAPI';
import {
  createTaskUser,
  deleteTaskUser,
  patchTaskUser,
} from '../../../API/TaskAPI';

import AccordionArrow from 'components/UI/AccordionArrows/AccordionArrow/AccordionArrow';
import StepBtn from 'components/UI/Btns/StepBtn';
import Comment from 'components/UI/Comment/Comment';
import Files from 'components/UI/Files/Files';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import ModalCreateContract from 'components/UI/Modals/ModalCreateContract/ModalCreateContract';
import ModalCreateSk from 'components/UI/Modals/ModalCreateSk/ModalCreateSk';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import MyInputFile from 'components/UI/MyInput/MyInputFile';
import TextAreaAutoSize from 'components/UI/MyTextArea/TextAreaAutoSize';
import Section from 'components/UI/Section/Section';
import StatusDropdown from 'components/UI/StatusDropdown/StatusDropdown';
import UploadFilesContainer from 'components/UI/UploadFilesContainer/UploadFilesContainer';

import SuccessBtn from '../../UI/Btns/SuccessBtn';
import DeleteRow from '../../UI/DeleteRow/DeleteRow';
import HistoryСhanges from '../../UI/HistoryСhanges/HistoryСhanges';
import ModalConfirm from '../../UI/Modals/ModalConfirm/ModalConfirm';
import ModalCreateCost from '../../UI/Modals/ModalCreateCost/ModalCreateCost';
import ProjectPageTitle from '../../UI/ProjectPageTitle/ProjectPageTitle';
import Table from '../../UI/Table/Table';

import { useFetching } from 'hooks/useFetching';
import { useLogout } from 'hooks/useLogout';
import { useProgress } from 'hooks/useProgress';

import { APPLICATION_TYPES } from 'utils/consts';
import { getSum, numberToSum } from 'utils/getSums';
import { getDate, getFormattedDate } from '../../../utils/getDate';

import {
  columnsForTableMargin,
  styleForContractLastElement,
  stylesForContractElement,
  stylesForMyDropdown,
  TABLE_PARAMS_MARGIN,
} from './consts';

import CommonInfo from './components/CommonInfo';
import FilesInfo from './components/FilesInfo';

import classes from './ProjectPage.module.scss';

const ProjectPageMarketing = observer(() => {
  const {
    modal, toast, user, comments,
  } = useContext(Context);
  const navigate = useNavigate();
  const { id } = useParams();
  const isFirstRender = useRef(true);
  const table = useRef();
  const [logout] = useLogout();
  const controller = new AbortController();

  const [isVisible, setIsVisible] = useState(false);
  const [step, setStep] = useState(0);

  const [title, setTitle] = useState('');

  const [data, setData] = useState({});
  const [commonInfo, setCommonInfo] = useState({});

  const [links, setLinks] = useState('');
  const [files, setFiles] = useState([]);

  const [fetching, setFetching] = useState(true);
  const [sentComment, setSentComment] = useState({ text: '' });

  const [filterState, setFilterState] = useState({});
  const [tasks, setTasks] = useState([]);

  const [publicationsFilterState, setPublicationsFilterState] = useState({});
  const [publications, setPublications] = useState([]);
  const [margin, setMargin] = useState([]);
  const [marginTotal, setMarginTotal] = useState([]);
  const [estimate, setEstimate] = useState([]);
  const [estimateTotal, setEstimateTotal] = useState([]);
  const [excessMargin, setExcessMargin] = useState(false);

  const [history, setHistory] = useState([]);

  const [ourLegalEntity, setOurLegalEntity] = useState([]);

  const [clientsList, setСlientsList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [directorList, setDirectorList] = useState([]);
  const [responsibleList, setResponsibleList] = useState([]);
  const [departamentList, setDepartmentList] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const [teams, setTeams] = useState([]);

  const [active, setActive] = useState({
    tasks: true,
    publications: true,
    profit: true,
    margin: true,
    expenses: true,
    estimate: true,
  });

  const [applicationInModal, setApplicationInModal] = useState({
    id: null,
    application: '',
  });

  const [fetchHistory] = useFetching(async () => {
    const data = await getHistory(id);
    setHistory(data);
  });

  const [fetchComments, isCommentsLoading] = useFetching(
    async (page, limit, signal, filter, update) => {
      await check().catch(() => logout());

      const data = await getComments(page, limit, signal, filter, id);

      comments.setCommentsTotalCount(data.count);

      if (update === 'update' || comments.commentsPage === 1) {
        comments.setCommentsPage(2);
        comments.setComments(data.results, 'update');
      } else {
        comments.setCommentsPage(comments.commentsPage + 1);
        comments.setComments(data.results);
      }

      setFetching(false);
    },
  );

  const totalPages = Math.ceil(
    comments.commentsTotalCount / comments.commentsLimit,
  );

  const [fetchProject, isLoading, fetchError] = useFetching(async (update) => {
    const serverData = await fetchOneProject(id);

    if (update === 'title') {
      setData(serverData);
      setTitle(serverData.name);
      fetchHistory();
      return;
    }
    if (update === 'application') {
      setData(serverData);
      return;
    }
    if (update === 'commonInfo') {
      setData(serverData);
      setCommonInfo(serverData);
      fetchHistory();
      return;
    }
    if (update === 'links') {
      setData(serverData);
      fetchHistory();
      return;
    }
    if (update === 'files') {
      setData(serverData);
      setFiles(serverData.files);
      fetchHistory();
      return;
    }

    setData(serverData);
    setTitle(serverData.name);
    setCommonInfo(serverData);
    setLinks(serverData.file_links);
    setFiles(serverData.files);
  });

  const [fetchTasks, isTasksLoading] = useFetching(async (filter) => {
    const data = await fetchOneProjectTasks(id, filter);
    setTasks(data);
  });

  const [fetchPublications, isPublicationsLoading] = useFetching(
    async (filter) => {
      const data = await fetchOneProjectPublications(id, filter);
      setPublications(data);
    },
  );

  const [fetchMargin] = useFetching(async () => {
    const data = await fetchOneProjectMargin(id);
    setMargin(data.margin_distribution);
    setMarginTotal(data);
  });

  const [fetchEstimate] = useFetching(async () => {
    const data = await fetchOneProjectEstimate(id);
    setEstimate(data.estimates);
    setEstimateTotal(data);
  });

  useEffect(() => {
    check()
      .then(() => {
        fetchProject();
        fetchTasks();
        fetchPublications();
        fetchMargin();
        fetchEstimate();
        fetchHistory();
        fetchTeams().then((data) => setTeams(data));

        getClients().then((data) => setСlientsList(data));

        autocompleteLegalEntities().then((data) => setOurLegalEntity(data));

        fetchDepartments().then((dataDepartments) => setDepartmentList(dataDepartments));

        fetchStaff({ role: 1, is_active: true })
          .then((dataDirector) => setDirectorList(dataDirector));

        fetchStaff({ job: 0, is_active: true })
          .then((dataManagers) => setManagerList(dataManagers));

        fetchStaff({ job: [4, 5, 6], is_active: true })
          .then((dataResponsible) => setResponsibleList(dataResponsible));

        fetchStaff().then((staff) => setStaffList(staff));
      })
      .catch(() => logout());
  }, []);

  const createContract = () => {
    check()
      .then(() => {
        getBid(data.id).then((bidData) => {
          fetchProject('application');
          modal.setModalApplicationContractProps({
            id,
            name: bidData.project.name,
            client_name: data?.client?.name,
            ourLegalEntity,
            bidData,
            fetchProject,
            fetchPublications: () => fetchPublications(publicationsFilterState),
            fetchMargin,
            fetchEstimate,
            fetchHistory,
            isSent: data.bid_is_sent,
          });
          modal.setModalApplicationContractVisible(true);
        }).catch(() => {
          toast.setToastInfo('Обратитесь в техподдержку');
          toast.setShowError(true);
        });
      })
      .catch(() => logout());
  };

  const createSK = () => {
    const setModalPropsAndShow = (dataProps, bidId, isSent = false) => {
      modal.setModalApplicationSKProps({
        dataProps,
        fetchProject,
        fetchMargin,
        fetchEstimate,
        fetchHistory,
        ourLegalEntity,
        client_legal_entity: dataProps.project.client.legal_entity,
        bid_id: bidId,
        client_contacts: dataProps.project.client.contacts,
        isSent,
      });
      modal.setModalApplicationSKVisible(true);
    };

    check()
      .then(() => {
        if (!data.bid_is_sent) {
          toast.setToastInfo('Отправьте заявку на документы');
          toast.setShowError(true);
          return;
        }

        if (data.sk_bid === null) {
          const requestData = {
            project: { id: Number(id) },
            type: { id: 4 },
            payments: [{ date: '', sum: 0 }],
          };

          postSKBid(requestData).then((response) => {
            fetchProject();
            getSKBid(response.data.id).then((dataProps) => {
              setModalPropsAndShow(dataProps, response.data.id);
            });
          });
        } else {
          getSKBid(data.sk_bid).then((dataProps) => {
            setModalPropsAndShow(dataProps, data.bid, data.sk_bid_is_sent);
          });
        }
      })
      .catch(() => logout());
  };

  const createCost = () => {
    if (data.bid_is_sent) {
      modal.setModalApplicationCostVisible(true);
      modal.setModalApplicationCostProps({
        id, fetchMargin, fetchEstimate, fetchProject,
      });
    } else {
      toast.setToastInfo('Отправьте заявку на документы');
      toast.setShowError(true);
    }
  };

  useEffect(() => {
    switch (applicationInModal.application) {
      case 'Заявка на документы':
        createContract();
        setApplicationInModal({ id: null, application: '' });
        break;
      case 'Заявка на СК':
        createSK();
        setApplicationInModal({ id: null, application: '' });
        break;
      case 'Заявка на доп. расход':
        createCost();
        setApplicationInModal({ id: null, application: '' });
        break;
      default:
        break;
    }
  }, [applicationInModal]);

  const updateProject = (data, typeUpdate) => {
    check()
      .then(() => {
        patchOneProject(id, data)
          .then(() => {
            toast.setToastInfo('Данные сохранены');
            toast.setShowSuccess(true);
            fetchProject(typeUpdate);
          })
          .catch((error) => {
            if (error.response.data.client) {
              toast.setToastInfo('Выберите клиента из списка');
              toast.setShowError(true);
            } else if (error.response.data.director) {
              toast.setToastInfo('Выберите руководителя из списка');
              toast.setShowError(true);
            } else {
              toast.setToastInfo('Произошла ошибка');
              toast.setShowError(true);
            }
          });
      })
      .catch(() => logout());
  };

  const [progressFiles, addFileFunc] = useProgress(
    setIsVisible,
    fetchProject,
    id,
    logout,
    uploadProjectFile,
  );

  const addFileHandler = (event) => {
    const files = [...event.target.files];
    files.forEach((file) => {
      addFileFunc(file);
    });
  };

  const deleteFileHandler = (name, fileId) => {
    modal.setModalConfirmVisible(true);
    modal.setModalConfirmProps({
      text: `Вы хотите удалить файл ${name}?`,
      click: () => {
        check()
          .then(() => {
            deleteProjectFile(fileId).then(() => {
              fetchProject('files');
            });
          })
          .catch(() => logout());
      },
    });
  };

  /* Запрос на сервер при скролле до конца таблицы */

  useEffect(() => {
    if (fetching) {
      if (comments.commentsFilter) {
        fetchComments(
          comments.commentsPage,
          comments.commentsLimit,
          controller.signal,
          comments.commentsFilter,
        );
      } else {
        fetchComments(
          comments.commentsPage,
          comments.commentsLimit,
          controller.signal,
        );
      }
    }

    return () => {
      controller.abort();
    };
  }, [fetching]);

  /* Когда доскролили до нижней части таблицы (первое условие)
  Проверяем текущую страницу с общим количеством страниц(второе условие)
  Далее делаем запрос
  */
  const scrollHandler = (event) => {
    if (
      event.target.scrollHeight
        - (event.target.scrollTop + event.target.clientHeight)
        < 600
      && comments.commentsPage <= totalPages
    ) {
      setFetching(true);
    }
  };

  // Наблюдение за скролломы
  useEffect(() => {
    if (isCommentsLoading) return;
    if (table.current) {
      const element = table.current;
      element.addEventListener('scroll', scrollHandler);
      return function () {
        element.removeEventListener('scroll', scrollHandler);
      };
    }
  }, [isCommentsLoading]);

  const taskDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          patchTaskUser(id, data).then(() => {
            fetchHistory();
          });
        })
        .catch(() => logout());
    }, 350),
    [],
  );

  const updateTask = (id, fieldName, value) => {
    setTasks(
      tasks.map((element) => (element.id === id
        ? {
          ...element,
          [fieldName]: value,
        }
        : element)),
    );

    if (fieldName === 'status') {
      check()
        .then(() => {
          patchTaskUser(id, { status: { id: value } }).then(() => {
            fetchTasks(filterState);
            fetchHistory();
          });
        })
        .catch(() => logout());
    } else if (fieldName === 'deadline') {
      taskDebounce(id, { [fieldName]: getDate(value, true) });
    } else {
      taskDebounce(id, { [fieldName]: value });
    }
  };

  const addTask = () => {
    check()
      .then(() => {
        const data = {
          name: '',
          deadline: '',
          responsible: [],
          project: { id: Number(id) },
          status: { id: 0 },
        };
        createTaskUser(data).then(() => {
          fetchTasks(filterState);
          fetchHistory();
        });
      })
      .catch(() => logout());
  };

  const deleteTask = (id) => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить задачу?',
      click: () => {
        setTasks(tasks.filter((element) => element.id !== id));

        check()
          .then(() => {
            deleteTaskUser(id);
            fetchHistory();
          })
          .catch(() => logout());
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const addSubPublication = (performerID, publicationID, publication) => {
    const currentPerformer = publications.find((performer) => performer.id === performerID);
    const currentPublication = currentPerformer.publications.find((publication) => publication.id === publicationID);
    const data = {
      subpublications: [...currentPublication.subpublications, {
        deadline: '',
        link: '',
        type: { id: publication.type.id, name: publication.type.name },
      }],
    };

    check()
      .then(() => {
        patchOneProjectPublication(publicationID, data).then(() => {
          fetchPublications();
        });
      })
      .catch(() => logout());
  };

  const deleteSubPublication = (performerID, publicationID, subpublicationID) => {
    const currentPerformer = publications.find((performer) => performer.id === performerID);
    const currentPublication = currentPerformer.publications.find((publication) => publication.id === publicationID);
    const publicationWithoutSubtask = {
      ...currentPublication,
      subpublications: currentPublication?.subpublications?.filter(
        (subpublication) => subpublication.id !== subpublicationID,
      ),
    };

    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить подпубликацию?',
      click: () => {
        check()
          .then(() => {
            patchOneProjectPublication(publicationID, publicationWithoutSubtask).then(() => {
              fetchPublications();
            });
          })
          .catch(() => logout());
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const publicationDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          patchOneProjectPublication(id, data);
        })
        .catch(() => logout());
    }, 550),
    [],
  );

  const updatePublication = (id, fieldName, value) => {
    setPublications((prevPublications) => {
      const updatedPublications = prevPublications.map((element) => {
        if (element.publications.some((el) => el.id === id)) {
          const updatedPublication = element.publications.map((el) => {
            if (el.id === id) {
              return {
                ...el,
                [fieldName]: fieldName === 'deadline' ? getDate(value) : value,
              };
            }
            return el;
          });
          return {
            ...element,
            publications: updatedPublication,
          };
        }
        return element;
      });

      return updatedPublications;
    });

    if (fieldName === 'status') {
      check()
        .then(() => {
          patchOneProjectPublication(id, { status: value }).then(() => {
            fetchPublications(publicationsFilterState);
          });
        })
        .catch(() => logout());
    }

    if (fieldName === 'deadline') {
      patchOneProjectPublication(id, { deadline: getDate(value) })
        .then(() => {
          fetchPublications();
        });
    }

    if (fieldName === 'link') {
      publicationDebounce(id, { link: value });
    }
  };

  const subPublicationDebounce = useCallback(
    debounce((performerID, publicationID, subPublicationID, data) => {
      check()
        .then(() => {
          patchOneProjectPublication(publicationID, { subpublications: data });
        })
        .catch(() => logout());
    }, 550),
    [],
  );

  const updateSubPublication = (performerID, publicationID, subPublicationID, fieldName, value) => {
    setPublications((prevPublications) => {
      const updatedPublications = prevPublications.map((performer) => {
        if (performer.id === performerID) {
          const updatedPerformerPublications = performer.publications.map((publication) => {
            if (publication.id === publicationID) {
              const updatedSubPublications = publication.subpublications.map((subPublication) => {
                if (subPublication.id === subPublicationID) {
                  return {
                    ...subPublication,
                    [fieldName]: fieldName === 'deadline' ? getDate(value) : value,
                  };
                }
                return subPublication;
              });

              return {
                ...publication,
                subpublications: updatedSubPublications,
              };
            }
            return publication;
          });

          return {
            ...performer,
            publications: updatedPerformerPublications,
          };
        }
        return performer;
      });

      const updatedPerformer = updatedPublications.find((performer) => performer.id === performerID);
      const updatedPublication = updatedPerformer.publications.find((publication) => publication.id === publicationID);

      if (fieldName === 'status') {
        check()
          .then(() => {
            patchOneProjectPublication(publicationID, { subpublications: updatedPublication.subpublications }).then(() => {
              fetchPublications(publicationsFilterState);
            });
          })
          .catch(() => logout());
      }

      if (fieldName === 'deadline') {
        patchOneProjectPublication(publicationID, { subpublications: updatedPublication.subpublications })
          .then(() => {
            fetchPublications();
          });
      }

      if (fieldName === 'link') {
        subPublicationDebounce(performerID, publicationID, subPublicationID, updatedPublication.subpublications);
      }

      return updatedPublications;
    });
  };

  const applyFilter = (filter) => {
    check()
      .then(() => {
        fetchTasks(filter);
        setFilterState(filter);
      })
      .catch(() => logout());
  };

  const applyFilterPublication = (filter) => {
    check()
      .then(() => {
        fetchPublications(filter);
        setPublicationsFilterState(filter);
      })
      .catch(() => logout());
  };

  const marginDebounce = useCallback(
    debounce((id, data, startedMargin) => {
      check()
        .then(() => {
          updateOneProjectMargin(id, data)
            .then(() => fetchMargin())
            .catch((error) => {
              if (error.response.data.margin_distribution) {
                toast.setToastInfo('Сумма маржи не может быть больше маржи проекта');
                toast.setShowError(true);
                setMargin(startedMargin);
                updateOneProjectMargin(id, startedMargin);
              } else {
                toast.setToastInfo(error.response.data);
                toast.setShowError(true);
                setMargin(startedMargin);
                updateOneProjectMargin(id, startedMargin);
              }
            });
        })
        .catch(() => logout());
    }, 1000),
    [],
  );

  const updateMargin = (id, fieldName, value) => {
    const startedMargin = margin;
    setMargin((prev) => prev.map((element) => {
      if (fieldName === 'sum') {
        return element.id === id
          ? {
            ...element,
            [fieldName]: value,
          }
          : element;
      }
      if (fieldName === 'department') {
        return element.id === id
          ? {
            ...element,
            [fieldName]: { id: value.id, name: value.name },
          }
          : element;
      }
      if (fieldName === 'percent') {
        return element.id === id
          ? {
            ...element,
            [fieldName]: value,
            sum: (getSum(value) * data.margin) / 100,
          }
          : element;
      }
      return element.id === id
        ? {
          ...element,
          [fieldName]: { id: value.id },
        }
        : element;
    }));

    const updatedMargin = margin.map((element) => {
      if (fieldName === 'sum') {
        return element.id === id
          ? {
            ...element,
            [fieldName]: getSum(value),
          }
          : element;
      }
      if (fieldName === 'percent') {
        return element.id === id
          ? {
            ...element,
            [fieldName]: getSum(value),
            sum: (getSum(value) * data.margin) / 100,
          }
          : element;
      }
      return element.id === id
        ? {
          ...element,
          [fieldName]: { id: value.id },
        }
        : element;
    });

    marginDebounce(data.id, updatedMargin, startedMargin);
  };

  // Добавление нового пункта в таблицу РАСПРЕДЕЛЕНИЕ МАРЖИ
  const addMargin = () => {
    const newMargin = {
      sum: 0,
      team: null,
      department: null,
      staff: null,
    };
    const updatedMargin = [...margin, newMargin];
    setMargin((prev) => [...prev, newMargin]);

    check()
      .then(() => {
        updateOneProjectMargin(id, updatedMargin).then(() => fetchMargin());
      })
      .catch(() => logout());
  };

  const deleteMargin = (id) => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить строку из таблицы "Распределение маржи"?',
      click: () => {
        setMargin(margin.filter((element) => element.id !== id));
        const filtredMargin = margin.filter((element) => element.id !== id);
        marginDebounce(data.id, filtredMargin);
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const estimateDebounce = useCallback(
    debounce((id, data) => {
      check()
        .then(() => {
          updateOneProjectEstimate(id, data)
            .then(() => fetchEstimate())
            .catch((error) => {
              if (error) {
                toast.setToastInfo('Поле "Цена продажи" можно изменить только у БЛ договора');
                toast.setShowError(true);
              }
            });
        })
        .catch(() => logout());
    }, 1250),
    [],
  );

  const updateEstimate = (id, fieldName, value) => {
    setEstimate((prev) => prev.map((element) => {
      if (fieldName === 'sale_price' && element.id === id) {
        return {
          ...element,
          [fieldName]: value,
        };
      }
      return element;
    }));

    const updatedEstimate = { sale_price: getSum(value) };

    estimateDebounce(id, updatedEstimate);
  };

  // Модальное окно удаления проекта
  const deleteProjectHandler = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить проект?',
      click: () => {
        check()
          .then(() => {
            deleteProject(id).then(() => {
              navigate('/');
            });
          })
          .catch(() => logout());
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const addSubTask = (e, id) => {
    const newTask = {
      name: '',
      deadline: '',
      responsible: [],
      project: { id: Number(id) },
      status: { id: 0 },
    };
    const currentTasks = tasks.find((el) => el.id === id);
    currentTasks?.subtasks.unshift(newTask);

    check()
      .then(() => {
        patchTaskUser(id, currentTasks).then(() => {
          fetchHistory();
          fetchTasks();
        });
      })
      .catch(() => logout());
  };

  const updateSubTask = (id, fieldName, value) => {
    const currentTask = tasks.find((task) => task.subtasks.find((subtask) => subtask.id === id));
    setTasks(
      tasks.map((task) => (task.id === currentTask.id
        ? {
          ...task,
          subtasks: [...task.subtasks].map((subtask) => (subtask.id === id
            ? {
              ...subtask,
              [fieldName]: value,
            }
            : subtask)),
        }
        : task)),
    );

    if (fieldName === 'deadline') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          deadline: getFormattedDate(value),
        }
        : subtask));
      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(
            () => {
              fetchTasks(filterState);
              fetchHistory();
            },
          );
        })
        .catch(() => logout());
    } else if (fieldName === 'status') {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          status: { id: value },
        }
        : subtask));

      check()
        .then(() => {
          patchTaskUser(currentTask.id, { subtasks: editedSubtasks }).then(
            () => {
              fetchTasks(filterState);
              fetchHistory();
            },
          );
        })
        .catch(() => logout());
    } else {
      const editedSubtasks = currentTask.subtasks.map((subtask) => (subtask.id === id
        ? {
          ...subtask,
          [fieldName]: value,
        }
        : subtask));
      taskDebounce(currentTask.id, { subtasks: editedSubtasks });
    }
  };

  const deleteSubTask = (parentId, subtaskId) => {
    const currentTask = tasks.find((task) => task.id === parentId);
    const taskWithoutSubtask = {
      ...currentTask,
      subtasks: currentTask.subtasks.filter(
        (subtask) => subtask.id !== subtaskId,
      ),
    };
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить подзадачу?',
      click: () => {
        check()
          .then(() => {
            patchTaskUser(parentId, taskWithoutSubtask).then(() => {
              fetchHistory();
              fetchTasks();
            });
          })
          .catch(() => logout());
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const [commentsFiles, setCommentsFiles] = useState([]);

  const addFileHandlerComment = (event) => {
    const addedFiles = Array.from(event.target.files);
    setCommentsFiles((prev) => [...prev, ...addedFiles]);
  };
  const removeFileHandler = (index) => {
    setCommentsFiles((prev) => prev.filter((_, i) => i !== index));
  };
  const [showUsersList, setShowUsersList] = useState(false);
  const [selectedUsersId, setSelectedUsersId] = useState([]);

  const [currentTag, setCurrentTag] = useState('');

  const formatUserName = (fullname) => {
    const [firstName, lastName] = fullname.split(' ');
    return `@${firstName}_${lastName}`;
  };

  const leaveCommentOnChange = (e) => {
    setSentComment({ text: e.target.value });

    const lastIndex = e.target.value.lastIndexOf('@');

    if (lastIndex !== -1) {
      const substring = e.target.value.slice(lastIndex);
      const match = substring.match(/@([a-zA-Zа-яА-ЯёЁ0-9_]*)/);

      if (match) {
        setShowUsersList(true);
        setCurrentTag({
          value: match[1],
          index: lastIndex, // сохраняем индекс
        });
        return;
      }
    }

    setShowUsersList(false);
    setCurrentTag(null);
  };

  const leaveCommentHandler = (e) => {
    let finalCommentText = sentComment.text;

    selectedUsersId.forEach((userObj) => {
      const user = staffList.find((u) => u.id === userObj.id);
      if (user) {
        const regex = new RegExp(formatUserName(user.fullname), 'g');
        finalCommentText = finalCommentText.replace(
          regex,
          `<b style="color:#99B3FF;">@${user.fullname}</b>`,
        );
      }
    });

    const data = new FormData();

    commentsFiles.forEach((file, index) => {
      data.append(file.name, file);
    });

    const body = { text: finalCommentText, tags: selectedUsersId };

    data.append('data', JSON.stringify(body));

    postComment(id, data).then(() => {
      setSentComment({ text: '' });
      setSelectedUsersId([]);
      toast.setToastInfo('Комментарий отправлен');
      toast.setShowSuccess(true);
      setFetching(true);
      setCommentsFiles([]);
      comments.setCommentsPage(1);
    });
  };

  const commentRef = useRef(null);

  const handleUserSelect = (user) => {
    if (!currentTag) return;

    const formattedName = formatUserName(user.fullname);

    // Обновляем текст, основываясь на индексе, где начался тег
    const newUserText = [
      sentComment.text.slice(0, currentTag.index), // текст до @
      formattedName,
      sentComment.text.slice(currentTag.index + currentTag.value.length + 1), // текст после @
    ].join('');

    setSentComment({ text: newUserText });
    setSelectedUsersId((prevUsers) => [...prevUsers, { id: user.id }]);
    setShowUsersList(false);
    setCurrentTag(null); // сбрасываем значение после использования
  };

  useEffect(() => {
    if (!showUsersList) {
      if (commentRef.current) {
        commentRef.current.focus();
      }
    }
  }, [commentRef, showUsersList]);

  // Сверяем сумму маржи в проекте и маржу из таблицы распределния
  useEffect(() => {
    if (data.margin && margin.length > 0) {
      const marginSum = margin.reduce((acc, el) => acc + el.sum, 0);
      if (marginSum > data.margin) {
        setExcessMargin(true);
      } else {
        setExcessMargin(false);
      }
    }
  }, [data.margin, margin]);

  const navigateBack = () => {
    navigate(-1);
    comments.setCommentsPage(1);
    comments.setComments([]);
  };

  // При первом рендере показываем крутилку
  if (isFirstRender.current) {
    if (isLoading || isTasksLoading || isPublicationsLoading) {
      return <Spinner animation="grow" />;
    }
    isFirstRender.current = false;
  }

  const tableStyles = {
    0: step === 0 ? {} : { display: 'none' },
    1: step === 1 ? {} : { display: 'none' },
    2: step === 2 ? {} : { display: 'none' },
    3: step === 3 ? {} : { display: 'none' },
    4: step === 4 ? {} : { display: 'none' },
  };

  const steps = [
    { text: 'Информация', index: 0 },
    { text: 'Коммуникация', index: 1 },
    { text: 'Публикации', index: 2 },
    { text: 'Задачи', index: 3 },
    { text: 'Маржа', index: 4 },
  ];

  const contractElements = [
    {
      id: 1, text: 'Заявка на документы', placeholder: data.bid_status, style: stylesForContractElement,
    },
    {
      id: 2, text: 'Заявка СК', placeholder: data.sk_bid_status, style: stylesForContractElement,
    },
    {
      id: 3, text: 'Заявка на дополнительный расход', placeholder: data.expense_bid_status, style: styleForContractLastElement,
    },
  ];

  const columnsTasks = [
    {
      id: 'name',
      label: 'Задача',
      filterOptions: [
        {
          id: '0',
          label: 'Невыполненные задачи',
          click: () => applyFilter({ status: 1 }),
        },
        {
          id: '1',
          label: 'Выполненные задачи',
          click: () => applyFilter({ status: 0 }),
        },
      ],
    },
    {
      id: 'responsible',
      label: 'Ответственный',
      filterOptions: [
        {
          id: 'responsible',
          click: (value) => applyFilter({ responsible_name: value }),
        },
      ],
    },
    {
      id: 'deadline',
      label: 'Дедлайн',
      filterOptions: [
        {
          id: 'asc',
          label: 'По возрастанию',
          click: () => applyFilter({ deadline: 0 }),
        },
        {
          id: 'desc',
          label: 'По убыванию',
          click: () => applyFilter({ deadline: 1 }),
        },
      ],
    },
    {
      id: 'status',
      label: 'Статус',
      filterOptions: [
        {
          id: 'status',
          click: (value) => applyFilter({ status: value }),
        },
      ],
    },
  ];

  const columnsPublication = [
    {
      id: 'deadline',
      label: 'Дата',
      filterOptions: [
        {
          id: 'asc',
          label: 'По возрастанию',
          click: () => applyFilterPublication({ deadline: 0 }),
        },
        {
          id: 'desc',
          label: 'По убыванию',
          click: () => applyFilterPublication({ deadline: 1 }),
        },
      ],
    },
    {
      id: 'blogger',
      label: 'Исполнитель',
      filterOptions: [
        {
          id: 'responsible',
          click: (value) => applyFilterPublication({ blogger: value }),
        },
      ],
    },
    {
      id: 'format',
      label: 'Формат публикации',
      filterOptions: [
        {
          id: 'responsible',
          click: (value) => applyFilterPublication({ type: value }),
        },
      ],
    },
    {
      id: 'link',
      label: 'Ссылка на соцсеть',
    },
    {
      id: 'linkPublication',
      label: 'Ссылка на публикацию',
    },
    {
      id: 'token',
      label: 'Токен',
    },
  ];

  const columnsEstimate = [
    { id: 'contractor', label: 'Статья' },
    { id: 'payment_method', label: 'Способ оплаты' },
    { id: 'payment_status', label: 'Статус оплаты' },
    { id: 'total_sum', label: 'Себестоимость (Без НДС)' },
    { id: 'sale_price', label: 'Цена продажи (До НДС)' },
    { id: 'margin', label: 'Маржа' },
  ];

  const columnsOneTableRowMargin = [
    { id: 'text', label: 'Итого' },
    { id: 'total_sum', label: numberToSum(marginTotal?.total_sum) },
    { id: 'total_percent', label: numberToSum(marginTotal?.total_percent) },
  ];

  const columnsOneTableRowEstimate = [
    { id: 'text', label: 'Итого' },
    { id: 'payment_method', label: '' },
    { id: 'payment_status', label: '' },
    { id: 'total_sum', label: numberToSum(estimateTotal?.total_sum, true) },
    { id: 'total_percent', label: numberToSum(estimateTotal?.total_sale_price, true) },
    { id: 'total_percent', label: numberToSum(estimateTotal?.total_margin, true) },
  ];

  return (
    <>
      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />
      {!fetchError && (
        <>
          <UploadFilesContainer
            progressFiles={progressFiles}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
          />

          {/* ШАПКА СТРАНИЦЫ */}
          <h1 className={classes.header}>
            <SuccessBtn
              text="Вернуться назад"
              onClick={navigateBack}
              type="white"
              back
              style={{ padding: '4px 10px 4px 16px', fontSize: '12px' }}
            />
            <div className={classes.project__title}>
              <ProjectPageTitle
                title={title}
                setTitle={setTitle}
                updateContract={updateProject}
              >
                № {data.project_id}
              </ProjectPageTitle>

              <StatusDropdown
                id={commonInfo.id}
                status={commonInfo.status.id}
                manager
                style={{ display: 'flex', padding: '5px 14px' }}
              />
            </div>

            <MyDropdown
              defaultText="Создать заявку"
              selected={applicationInModal}
              setSelected={setApplicationInModal}
              list={APPLICATION_TYPES.LIST}
              style={stylesForMyDropdown}
              classNameItem={classes.dropdown_item}
            />
          </h1>

          {/* ШАГИ */}

          <div className={classes.btn__steps}>
            {steps.map(({ text, index }) => (
              <StepBtn
                key={index}
                active={step === index}
                text={text}
                onClick={() => setStep(index)}
                style={{ width: 280 }}
              />
            ))}
          </div>

          {/* ИНФОРМАЦИЯ */}

          <CommonInfo
            projectCard
            project={commonInfo}
            managerList={managerList}
            responsibleList={responsibleList}
            directorList={directorList}
            updateContract={updateProject}
            clientsList={clientsList}
            contracts={data.contracts}
            tableStyle={tableStyles[0]}
          />

          <Section title="Заявки" style={tableStyles[0]}>
            {contractElements.map(({
              id, text, placeholder, style,
            }) => (
              <ContractElement key={id} text={text} textStyle={style}>
                <InputElement placeholder={placeholder} style={{ width: '330px' }} disabled />
              </ContractElement>
            ))}
          </Section>

          <HistoryСhanges history={history} tableStyle={tableStyles[0]} />

          {(user.user.role.id === 0 || user.user.role.id === 1) && (
          <DeleteRow
            title="Удаление проекта"
            deleteProjectHandler={deleteProjectHandler}
            tableStyle={tableStyles[0]}
          />
          )}

          {/* КОММУНИКАЦИЯ */}

          <FilesInfo
            addFileHandler={addFileHandler}
            files={files}
            id={id}
            deleteFileHandler={deleteFileHandler}
            links={links}
            setLinks={setLinks}
            updateProject={updateProject}
            tableStyle={tableStyles[1]}
          />

          <Section title="Внутренняя коммуникация" style={tableStyles[1]}>
            <div className={classes.comments_block}>
              <TextAreaAutoSize
                ref={commentRef}
                placeholder="Введите текст..."
                value={sentComment.text}
                onChange={leaveCommentOnChange}
                style={{ marginBottom: '20px', minHeight: '100px', overflow: 'auto' }}
              />
              {commentsFiles?.length > 0 && (
              <div className={classes.files} style={{ marginBottom: '20px' }}>
                {commentsFiles.map((file, index) => (
                  <Files
                    fileNameStyle={{ width: '100px' }}
                    key={index}
                    style={{ marginRight: '10px' }}
                    file={file}
                    deleteFileHandler={() => removeFileHandler(index)}
                  />
                ))}
              </div>
              )}
              {showUsersList && (
              <div className={classes.usersList}>
                {staffList
                  .filter((user) => {
                    if (!currentTag) return true;
                    return user.fullname.toLowerCase().includes(currentTag?.value?.toLowerCase());
                  })
                  .map((user) => (
                    <div
                      key={user.id}
                      onClick={() => handleUserSelect(user)}
                    >
                      {user.fullname}
                    </div>
                  ))}
              </div>
              )}
              <MyInputFile
                htmlFor="comments"
                text="Добавить файл"
                onChange={(event) => addFileHandlerComment(event)}
                multiple
                accept=".ppt, .pptx, .xls, .xlsx, .doc, .docx, .jpeg, .jpg, .png, .pdf, .key, .heif"
              />
            </div>
            <SuccessBtn
              text="Отправить"
              btnType="button"
              className={classes.addComment_button}
              onClick={leaveCommentHandler}
            />

            <div className={classes.comments__container} ref={table}>
              {comments.comments?.map((comment, i) => (
                <Comment
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  data={comment}
                />
              ))}
            </div>
          </Section>

          {/* ПУБЛИКАЦИИ */}

          <div style={tableStyles[2]}>
            <div style={{ marginBottom: '20px' }}>
              <div
                className={classes.accordion_block}
                onClick={() => setActive((prev) => ({
                  ...prev,
                  publications: !prev.publications,
                }))}
              >
                <h6 className={classes.title}>Публикации</h6>
                <div className={classes.accordion_arrow}>
                  {active.publications ? 'Свернуть' : 'Развернуть'}
                  <AccordionArrow isTable isActive={active.publications} />
                </div>
              </div>

              <CSSTransition in={active.publications} unmountOnExit timeout={50}>
                <Table
                  headerStyle={{ marginTop: 10 }}
                  headCellStyle={{ padding: ' 20px 15px' }}
                  cellStyle={{ overflow: 'hidden' }}
                  style={{ marginBottom: 25 }}
                  type="projectPublications"
                  updateFunc={updatePublication}
                  updateChildren={updateSubPublication}
                  addChildren={addSubPublication}
                  deleteChildren={deleteSubPublication}
                  columns={columnsPublication}
                  data={publications}
                  tableName="publications"
                  noAdd
                />
              </CSSTransition>
            </div>
          </div>

          {/* ЗАДАЧИ */}

          <div style={tableStyles[3]}>
            <div style={{ marginBottom: '20px' }}>
              <div className={classes.accordion_block}>
                <h6 className={classes.title}>Задачи по проекту</h6>
                <div
                  className={classes.accordion_arrow}
                  onClick={() => setActive((prev) => ({ ...prev, tasks: !prev.tasks }))}
                >
                  {active.tasks ? 'Свернуть' : 'Развернуть'}
                  <AccordionArrow isTable isActive={active.tasks} />
                </div>
              </div>

              <CSSTransition timeout={50} in={active.tasks} unmountOnExit>
                <Table
                  headerStyle={{ marginTop: 10 }}
                  headCellStyle={{ padding: ' 20px 15px' }}
                  type="projectTasksMarketing"
                  updateFunc={updateTask}
                  addHandler={addTask}
                  addChildren={addSubTask}
                  deleteHandler={deleteTask}
                  updateChildren={updateSubTask}
                  deleteChildren={deleteSubTask}
                  noCheckbox
                  columns={columnsTasks}
                  data={tasks}
                  responsibleList={[...managerList, ...responsibleList]}
                  tableName="projectTasks"
                  fetchMyTasks={fetchTasks}
                />
              </CSSTransition>
            </div>
          </div>

          {/* МАРЖА */}

          <div style={tableStyles[4]}>
            <div style={{ marginBottom: '20px' }}>
              <div className={classes.accordion_block}>
                <h6 className={classes.title}>Распределение маржи</h6>
                <div
                  className={classes.accordion_arrow}
                  onClick={() => setActive((prev) => ({ ...prev, margin: !prev.margin }))}
                >
                  {active.margin ? 'Свернуть' : 'Развернуть'}
                  <AccordionArrow isTable isActive={active.margin} />
                </div>
              </div>

              <CSSTransition in={active.margin} unmountOnExit timeout={50}>
                <div>
                  <div className={[
                    excessMargin ? classes.totalMarginExcess : classes.totalMargin,
                    marginTotal.validate_margin && TABLE_PARAMS_MARGIN[marginTotal.validate_margin].className].join(' ')}
                  >
                    <b>Маржа проекта</b>
                    <span style={{ marginLeft: '40px' }}>
                      {numberToSum(data.margin) || '0,00'}
                    </span>
                  </div>
                  <Table
                    responsibleList={staffList}
                    headerStyle={{ marginTop: 20 }}
                    cellStyle={{ display: 'block', height: '100%', padding: '0px' }}
                    addHandler={addMargin}
                    addText="Добавить"
                    updateFunc={updateMargin}
                    deleteHandler={deleteMargin}
                    type="projectMargin"
                    columns={columnsForTableMargin}
                    tableName="projectMargin"
                    data={margin}
                    noCheckbox
                    teams={teams}
                    departamentList={departamentList}
                    columnsOneTableRow={columnsOneTableRowMargin}
                    rowName="projectPageMargin"
                  />
                </div>
              </CSSTransition>
            </div>
          </div>

          <div style={tableStyles[4]}>
            <div style={{ marginBottom: '20px' }}>
              <div
                className={classes.accordion_block}
                onClick={() => setActive((prev) => ({ ...prev, estimate: !prev.estimate }))}
              >
                <h6 className={classes.title}>Смета</h6>
                <div className={classes.accordion_arrow}>
                  {active.estimate ? 'Свернуть' : 'Развернуть'}
                  <AccordionArrow isTable isActive={active.estimate} />
                </div>
              </div>

              <CSSTransition in={active.estimate} unmountOnExit timeout={50}>
                <Table
                  headerStyle={{ marginTop: 10 }}
                  cellStyle={{ display: 'block', height: '100%', padding: '0px' }}
                  type="projectEstimate"
                  columns={columnsEstimate}
                  tableName="projectEstimate"
                  data={estimate}
                  updateFunc={updateEstimate}
                  noCheckbox
                  columnsOneTableRow={columnsOneTableRowEstimate}
                  rowName="projectPageEstimate"
                />
              </CSSTransition>
            </div>
          </div>

          {/* МОДАЛЬНЫЕ ОКНА */}

          <ModalCreateContract
            show={modal.modalApplicationContractVisible}
            onHide={() => modal.setModalApplicationContractVisible(false)}
            props={modal.modalApplicationContractProps}
          />

          <ModalConfirm
            show={modal.modalConfirmVisible}
            onHide={() => modal.setModalConfirmVisible(false)}
            props={modal.modalConfirmProps}
          />

          <ModalCreateSk
            show={modal.modalApplicationSKVisible}
            onHide={() => modal.setModalApplicationSKVisible(false)}
            props={modal.modalApplicationSKProps}
          />

          <ModalCreateCost
            show={modal.modalApplicationCostVisible}
            onHide={() => modal.setModalApplicationCostVisible(false)}
            props={modal.modalApplicationCostProps}
          />
        </>
      )}
    </>
  );
});

export default ProjectPageMarketing;
