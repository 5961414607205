import { $authHost } from 'API';

export const getBloggersWithoutContract = async (id) => {
  const { data } = await $authHost.get(
    `/expense_bid/project/${id}/bloggers_without_contract/`,
  );
  return data;
};

export const getBloggersWithContract = async (id) => {
  const { data } = await $authHost.get(
    `/payment_bid/project/${id}/bloggers_with_contract/`,
  );
  return data;
};

export const getBloggersInContract = async (id) => {
  const { data } = await $authHost.get(
    `/sk_bid/bid/${id}/bloggers/`,
  );
  return data;
};

export const getBid = async (id) => {
  const response = await $authHost.get(`/document_bid/contract/${id}`);
  return response.data;
};

export const putBid = async (project_id, data) => {
  const response = await $authHost.put(`/document_bid/contract/${project_id}/`, data);
  return response;
};

export const putBidDraft = async (project_id, data) => {
  const response = await $authHost.put(`/document_bid/draft/${project_id}/`, data);
  return response;
};

export const deleteBloggerBID = async ({ bl_id }) => {
  await $authHost.delete(`/document_bid/blogger_contract/${bl_id}/`);
};

export const postBloggerDraft = async ({ parent_draft_id }) => {
  const response = await $authHost.post(`/document_bid/child_draft_create/${parent_draft_id}/`);
  return response;
};

export const deleteBloggerDraft = async ({ draft_id }) => {
  const response = await $authHost.delete(`/document_bid/child_draft_delete/${draft_id}/`);
  return response;
};

export const addBIDFile = async (files, options) => {
  const response = await $authHost.post(
    '/document_bid/contract_files',
    files,
    options,
  );
  return response;
};

export const addMarkingDataFile = async (files, options) => {
  const response = await $authHost.post(
    '/document_bid/marking_data_files',
    files,
    options,
  );
  return response;
};

export const deleteBIDFile = async (file_id) => {
  const response = await $authHost.delete(`/document_bid/contract_file/${file_id}/`);
  return response;
};

export const deleteMarkingDataFile = async (file_id) => {
  const response = await $authHost.delete(`/document_bid/marking_data_file/${file_id}/`);
  return response;
};

// SK BID

export const postSKBid = async (data) => {
  const response = await $authHost.post('/sk_bid/bid/', data);
  return response;
};

export const getSKBid = async (id) => {
  const response = await $authHost.get(`/sk_bid/bid/${id}/`);
  return response.data;
};

export const patchSKBid = async (id, data) => {
  const response = await $authHost.patch(`/sk_bid/bid/${id}/`, data);
  return response;
};

export const putSKBid = async (id, data) => {
  const response = await $authHost.put(`/sk_bid/bid/${id}/`, data);
  return response;
};

export const deletePaymentBID = async (id) => {
  await $authHost.delete(`/sk_bid/bid/${id}/`);
};

export const addSKBIDFile = async (id, files) => {
  await $authHost.post(`/sk_bid/bid/${id}/file/`, files);
};

export const getSKBIDFile = async (id) => {
  const response = await $authHost.get(`/sk_bid/bid/${id}/file/`);
  return response.data;
};

// Cost BID

export const postAdditionalExpenseBid = async (data) => {
  const response = await $authHost.post('/payment_bid/bid/', data);
  return response;
};

export const getCostFiles = async (id) => {
  const response = await $authHost.get(
    `/payment_card/payment/${id}/file/`,
  );
  return response;
};

export const addCostFile = async (id, files, options) => {
  const response = await $authHost.post(
    `/payment_card/payment/${id}/file/`,
    files,
    options,
  );
  return response;
};

export const addAdditionalExpenseBIDFile = async (id, files, options) => {
  const response = await $authHost.post(
    `/payment_bid/bid//${id}/file/`,
    files,
    options,
  );
  return response;
};

// Payment BID

export const postPaymentBid = async (data) => {
  const response = await $authHost.post('/bid/payment_bid/bid/', data);
  return response;
};

export const addPaymentBIDFile = async (id, files, options) => {
  const response = await $authHost.post(
    `/payment_card/payment/${id}/file/`,
    files,
    options,
  );
  return response;
};

export const approveCommentPatch = async (id) => {
  const response = await $authHost.patch(`/additional_comment/${id}/approve/`);
  return response;
};
