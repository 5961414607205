// @ts-nocheck
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import copy_icon from 'assets/icons/Btns/copy-icon.svg';
import debounce from 'lodash.debounce';
import { observer } from 'mobx-react-lite';
import { Context } from '../../../../index';

import { getClients } from 'API/ClientAPI';
import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { autocompleteClientLegalEntities, autocompleteLegalEntities } from 'API/LawyersAPI/AutocompleteAPI';
import { check } from 'API/UserAPI';

import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';
import CheckBox from 'components/UI/MyCheckbox/CheckBox';
import MyDropdown from 'components/UI/MyDropdown/MyDropdown';
import SearchBar from 'components/UI/SearchBar/SearchBar';
import Section from 'components/UI/Section/Section';

import { useLogout } from 'hooks/useLogout';
import { useStateNew } from 'hooks/useStateNew';

import { numberToSum } from 'utils/getSums';
import { isValidURL } from 'utils/isValidUrl';

import classes from '../OneCostPage.module.scss';

const defaultBankDetails = {
  bank: '',
  kc: '',
  rc: '',
  bik: '',
};

const PaymentInfoContract = observer((props) => {
  const {
    commonInfo, setCommonInfo, fetchCost, id,
  } = props;
  const logout = useLogout();
  const { user, modal, toast } = useContext(Context);

  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    let total = 0;
    commonInfo?.payment_dates.forEach((item) => {
      total += item.sum;
    });

    setTotalSum(total);
  }, [commonInfo?.payment_dates]);

  const [ourLegalEntities, setOurLegalEntities] = useState([]);

  const [clientLegalEntities, setClientLegalEntities] = useState([]);

  const [bankDetails, setBankDetails] = useState(defaultBankDetails);
  const [selectedBank, setSelectedBank] = useState({});

  const handleCopy = () => {
    const data = `
    ЮЛ исполнителя: ${commonInfo?.contract?.contractor_legal_entity?.legal_name || ''}
    ИНН: ${commonInfo?.contract?.contractor_legal_entity?.inn || ''}
    ОГРНИП: ${commonInfo?.contract?.contractor_legal_entity?.ogrn || commonInfo?.contract?.contractor_legal_entity?.ogrnip || ''}
    Юридический адрес: ${commonInfo?.contract?.contractor_legal_entity?.address}
    Банковские реквизиты:
    Р/С: ${bankDetails?.rc}
    Банк: ${bankDetails?.bank}
    БИК: ${bankDetails?.bik}
    К/С: ${bankDetails?.kc}
    № договора: ${commonInfo?.contract?.id}
    `;
    navigator.clipboard.writeText(data);
    toast.setToastInfo('Реквизиты скопированы');
    toast.setShowSuccess(true);
  };

  const openModalHandler = () => {
    modal.setModalEditPaymentsProps({
      commonInfo,
    });
    modal.setModalEditPaymentsVisible(true);
  };

  useEffect(() => {
    check()
      .then(() => {
        autocompleteLegalEntities().then((data) => setOurLegalEntities(data));
        autocompleteClientLegalEntities().then((data) => setClientLegalEntities(data));
      })
      .catch((error) => logout(error));
  }, []);

  const [clientLegalEntity, setClientLegalEntity, changeClientLegalEntity] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  const [ourLegalEntity, setOurLegalEntity, changeOurLegalEntity] = useStateNew([
    {
      fieldId: Date.now(),
      id: null,
      name: '',
    },
  ]);

  useEffect(() => {
    if (commonInfo) {
      const initialOurData = [{
        fieldId: Date.now(),
        id: commonInfo?.contract?.client_legal_entity?.id,
        name: commonInfo?.contract?.client_legal_entity?.legal_name,
      }];
      const initialClientData = [{
        fieldId: Date.now(),
        id: commonInfo?.contract?.contractor_legal_entity?.id,
        name: commonInfo?.contract?.contractor_legal_entity?.legal_name,
      }];

      setOurLegalEntity(initialOurData);
      setClientLegalEntity(initialClientData);
    }
  }, [commonInfo, setClientLegalEntity, setOurLegalEntity]);

  const [bankList, setBankList] = useState([]);

  const isDoneHandler = (id) => {
    if (user.user.role.id !== 2 || user.user.department.id === 2 || user.user.department.id === 3) {
      const newPaymentDates = commonInfo.payment_dates.map((date) => {
        if (date.id === id) {
          return { ...date, is_done: !date.is_done };
        }
        return date;
      });

      setCommonInfo({
        ...commonInfo,
        payment_dates: newPaymentDates,
      });

      check()
        .then(() => {
          patchOneCostBid(commonInfo?.id, { payment_dates: newPaymentDates }).then(() => {
            fetchCost();
          });
        })
        .catch((error) => logout(error));
    }
  };

  const saveLegalEntity = (type) => {
    if (type === 'client') {
      const temp = { id: clientLegalEntity[0]?.id };
      patchOneCostBid(commonInfo?.id, { contract: { id: commonInfo?.contract.id, contractor_legal_entity: temp } }).then(() => {
        fetchCost();
      });
    }
    if (type === 'our') {
      const temp = { id: ourLegalEntity[0]?.id };
      patchOneCostBid(commonInfo?.id, { contract: { id: commonInfo?.contract.id, client_legal_entity: temp } }).then(() => {
        fetchCost();
      });
    }
  };
  useEffect(() => {
    if (commonInfo?.contract) {
      if (commonInfo?.bank_details?.rc) {
        setBankDetails(
          {
            id: commonInfo?.bank_details?.id,
            bank: commonInfo?.bank_details?.bank,
            kc: commonInfo?.bank_details?.kc,
            rc: commonInfo?.bank_details?.rc,
            bik: commonInfo?.bank_details?.bik,
          },
        );
      } else {
        setBankDetails(
          {
            bank: commonInfo?.contract?.contractor_legal_entity?.bank_details[0]?.bank || '',
            kc: commonInfo?.contract?.contractor_legal_entity?.bank_details[0]?.kc || '',
            rc: commonInfo?.contract?.contractor_legal_entity?.bank_details[0]?.rc || '',
            bik: commonInfo?.contract?.contractor_legal_entity?.bank_details[0]?.bik || '',
            id: commonInfo?.contract?.contractor_legal_entity?.bank_details[0]?.id || '',
          },
        );
      }
    }
  }, [commonInfo]);

  const [activeSaveClient, setActiveSaveClient] = useState(false);
  const [activeSaveOur, setActiveSaveOur] = useState(false);

  useEffect(() => {
    if (commonInfo?.contract) {
      const list = commonInfo?.contract?.contractor_legal_entity?.bank_details.map((el) => ({
        id: el.id,
        name: el.rc,
      }));
      setBankList(list);
    }
  }, [commonInfo?.contract]);

  useEffect(() => {
    if (bankDetails?.rc) {
      setSelectedBank({ id: bankDetails?.id, name: bankDetails?.rc });
    }
  }, [bankDetails.id, bankDetails.name, bankDetails?.rc, bankList]);

  const [ppLink, setPPLink] = useState('');
  const [contractLink, setContractLink] = useState('');

  useEffect(() => {
    if (commonInfo?.pp_file_link) {
      setPPLink(commonInfo.pp_file_link);
    }
    if (commonInfo?.contract?.file_links) {
      setContractLink(commonInfo?.contract?.file_links);
    }
  }, []);

  const updateLink = useCallback(
    debounce((value, type) => {
      check()
        .then(() => {
          if (type === 'pp') {
            setPPLink(value);
            patchOneCostBid(id, { pp_file_link: value });
          }
          if (type === 'contract') {
            setContractLink(value);
            patchOneCostBid(id, {
              contract: {
                id: commonInfo?.contract?.id,
                file_links: value,
              },
            });
          }
        })
        .catch((error) => logout(error));
    }, 0),
    [],
  );

  useEffect(() => {
    // Проверяем, есть ли выбранный банк и есть ли банковские реквизиты
    if (selectedBank.id && bankDetails.id) {
      // Если выбранный банк отличается от текущих банковских реквизитов
      if (selectedBank.id !== bankDetails.id) {
        // Вызываем функцию check(), а затем обновляем данные на сервере
        check()
          .then(() => {
            patchOneCostBid(commonInfo?.id, { bank_details: { id: selectedBank.id } })
              .then(() => {
                fetchCost(); // Здесь вы можете обновить ваш локальный стейт
              });
          })
          .catch((error) => logout(error));
      }
    }
  }, [bankDetails.id, commonInfo?.id, fetchCost, logout, selectedBank.id]);

  return (
    <Section title="Оплата">
      <div className={classes.wrapper}>
        <ContractElement textStyle={{ width: '220px', minWidth: '220px' }} text="ЮЛ заказчика">
          <SearchBar
            style={{ width: '100%' }}
            text={ourLegalEntity[0]}
            setText={changeOurLegalEntity}
            list={ourLegalEntities}
            type="legal_details"
            containerClassName={classes.seacrh}
            onClick={() => setActiveSaveOur(true)}
          />
          {activeSaveOur && (
          <SuccessBtn
            text="Сохранить"
            style={{
              marginLeft: '10px',
              marginRight: '-120px',
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveLegalEntity('our')}
          />
          )}

        </ContractElement>

        <ContractElement textStyle={{ width: '220px', minWidth: '220px' }} text="ЮЛ исполнителя">
          <SearchBar
            style={{ width: '100%' }}
            text={clientLegalEntity[0]}
            setText={changeClientLegalEntity}
            list={clientLegalEntities}
            type="legal_details"
            containerClassName={classes.seacrh}
            onClick={() => setActiveSaveClient(true)}
          />
          {activeSaveClient && (
          <SuccessBtn
            text="Сохранить"
            style={{
              marginLeft: '10px',
              marginRight: '-120px',
              padding: '2px 20px',
              fontSize: 13,
            }}
            onClick={() => saveLegalEntity('client')}
          />
          )}
        </ContractElement>

        <ContractElement textStyle={{ width: '220px', minWidth: '220px' }} text="Реквизиты исполнителя">
          <SuccessBtn
            text={

              <img src={copy_icon} alt="copy-icon" />

          }
            onClick={handleCopy}
            isCopy
            type="white"
            style={{
              marginLeft: '20px',
              padding: '2px 2px 2px 2px',
              fontSize: '12px',
            }}
          />

          <div
            onClick={handleCopy}
            style={{
              cursor: 'pointer',
              marginLeft: '10px',
              padding: '2px 2px 2px 2px',
              fontSize: '12px',
            }}
          >Копировать реквизиты
          </div>

        </ContractElement>

        <InputElement
          style={{ color: '#212529' }}
          text="ИНН"
          value={commonInfo?.contract?.contractor_legal_entity?.inn || ''}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="ОГРНИП"
          value={commonInfo?.contract?.contractor_legal_entity?.ogrn || commonInfo?.contract?.contractor_legal_entity?.ogrnip || ''}
          textClassName="textClassName3"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Юридический адрес"
          value={commonInfo?.contract?.contractor_legal_entity?.address}
          textClassName="textClassName3"
          disabled
        />

        <ContractElement text="Банковские реквизиты" style={{ marginTop: '30px', marginBottom: '20px' }} textClassName="textClassName3" />

        <ContractElement text="Р/С" style={{ marginTop: '30px', marginBottom: '20px' }} textClassName="textClassName3">

          <MyDropdown
            selected={selectedBank}
            setSelected={setSelectedBank}
            list={bankList}
            classNameItem={classes.dropdown_item}
          />
        </ContractElement>

        <InputElement
          style={{ color: '#212529' }}
          text="Банк"
          value={bankDetails?.bank}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="БИК"
          value={bankDetails?.bik}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="К/С"
          value={bankDetails?.kc}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="№ договора"
          value={commonInfo?.contract?.id}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Ссылка на договор и акт"
          value={contractLink}
          setValue={setContractLink}
          disabled
          isLink={isValidURL(contractLink)}
          textClassName="textClassName2"
          onChange={(e) => { updateLink(e.target.value, 'contract'); }}

        />

        <InputElement
          style={{ color: '#212529' }}
          text="Статус договора"
          value={commonInfo?.contract?.status?.name}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Статус закрывающих документов"
          value={commonInfo?.financial_status?.name}
          textClassName="textClassName2"
          disabled
        />

        <ContractElement
          text="Порядок оплаты"
          elementClassName={classes.margin_zero}
        >

          <>

            <InputElement
              style={{ color: '#212529', marginLeft: '-40px' }}
              type="date"
              value={commonInfo?.date}
              date
              disabled
            />

            <InputElement
              style={{
                color: '#212529', height: '32px', width: '230px', marginLeft: '20px', marginBottom: '11px',
              }}
              className={commonInfo?.sum !== totalSum && commonInfo?.payment_dates.length > 0 && classes.red}
              value={numberToSum(commonInfo?.sum)}
              disabled
            />
          </>

        </ContractElement>

        {commonInfo?.payment_dates
        && (

          commonInfo?.payment_dates.map((item, index) => (
            <ContractElement
              key={item.id}
              text={index === 0 ? 'Фактическая оплата' : ''}
              elementClassName={classes.margin_zero}
            >

              <>

                <InputElement
                  style={{ color: '#212529', marginLeft: '-40px' }}
                  type="date"
                  value={item?.date}
                  date
                  disabled
                />

                <InputElement
                  style={{
                    color: '#212529',
                    height: '32px',
                    width: '230px',
                    marginLeft: '20px',
                    marginBottom: '11px',

                  }}
                  value={numberToSum(item?.sum)}
                  disabled
                />

                <CheckBox
                  onChange={() => isDoneHandler(item.id)}
                  className={classes.checkboxPP}
                  checked={item?.is_done}
                />

              </>

            </ContractElement>

          ))

        )}

        <div className={classes.buttonContainer}>
          <SuccessBtn
            text="Изменить порядок оплаты"
            style={{ padding: '4px 30px', fontSize: 13 }}
            onClick={openModalHandler}
          />
        </div>

        <div className={classes.message}><b>ОБРАТИТЬ ВНИМАНИЕ!</b><br />
          Данная функция предусмотрена только для форс-мажоров и изменений условий договора за собой не повлечет!<br />
          При увеличении количества траншей, общая сумма оплаты не может быть изменена!<br />
          Любые изменения условий договора (добавить/удалить/увеличить/уменьшить суммы оплаты) необходимо производить через Заявку на документы!
        </div>

        <InputElement
          style={{ color: '#212529' }}
          text="НДС"
          value={commonInfo?.contract?.is_nds ? 'Да' : 'Нет'}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Сумма НДС"
          value={commonInfo?.contract?.nds_sum}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Нужно ПП"
          value={commonInfo?.is_pp ? 'Да' : 'Нет'}
          textClassName="textClassName2"
          disabled
        />

        <InputElement
          style={{ color: '#212529' }}
          text="Ссылка на ПП"
          value={ppLink}
          onChange={(e) => { updateLink(e.target.value, 'pp'); }}
          textClassName="textClassName2"
          className={classes.input__link__padding}
          classNameLink={classes.input__link}
          isLink={isValidURL(ppLink)}
        />
      </div>

    </Section>
  );
});

export default PaymentInfoContract;
