// @ts-nocheck
import React from 'react';

import InputElement from 'components/UI/InputElements/InputElement';
import MyDateInput from 'components/UI/MyDateInput/MyDateInput';

import { getSumRuble } from 'utils/getSums';

import CancelBtn from '../../../Btns/CancelBtn';

import classes from '../ModalCreateContract.module.css';

const PaymentDateNoTime = React.memo((props) => {
  const {
    element, deleteDate, changeDate, onClick, placeholder, disabled, type,
  } = props;

  const click = () => {
    if (element.id && type === 'Step3') {
      deleteDate(element.id, true);
    } else {
      deleteDate(element.id ? element.id : element.reactId);
    }
  };

  const setDate = (value) => {
    changeDate(element.id ? element.id : element.reactId, 'date', value);
  };

  return (
    <div className={classes.assignment__item}>
      <MyDateInput
        className={classes.date}
        value={element?.date}
        setValue={setDate}
        onClick={onClick}
        disabled={disabled}
      />

      <InputElement
        type="number"
        value={getSumRuble(element?.sum)}
        onChange={(event) => changeDate(element.id ? element.id : element.reactId, 'sum', getSumRuble(event.target.value))}
        placeholder={placeholder || 'Сумма'}
        style={{ width: '100%', marginLeft: 20, marginBottom: 0 }}
        onClick={onClick}
        disabled={disabled}
      />

      {deleteDate && !element.id && (
        <CancelBtn
          onClick={click}
          style={{
            padding: '1px 8px',
            left: 412,
            position: 'absolute',
          }}
        />
      )}
    </div>
  );
});

export default PaymentDateNoTime;
