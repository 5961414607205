// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from 'index';

import {
  deletePaymentBID,
  getSKBid,
  patchSKBid,
  putSKBid,
} from 'API/ApplicationAPI';
import { check } from 'API/UserAPI';
import { getBloggersInContract } from '../../../../API/ApplicationAPI';
import { autocompleteClientLegalEntities } from '../../../../API/LawyersAPI/AutocompleteAPI';
import { fetchStaff } from '../../../../API/ManagerAPI/AutocompleteAPI';

import Align from 'components/UI/Align/Align';
import NewAddBtn from 'components/UI/Btns/NewAddBtn';
import SuccessBtn from 'components/UI/Btns/SuccessBtn';
import MyModal from 'components/UI/MyModal/MyModal';

import { useLogout } from 'hooks/useLogout';

import { getDate } from 'utils/getDate';
import { getSum, numberToSum } from 'utils/getSums';

import ModalConfirm from '../ModalConfirm/ModalConfirm';

import Step1 from './components/Step1';
import Step2 from './components/Step2/Step2';

import classes from './ModalCreateSk.module.scss';

const ModalCreateSk = observer(({ show, onHide, props }) => {
  const {
    ourLegalEntity,
    dataProps,
    client_contacts,
    bid_id,
    isSent,
    fetchProject,
    fetchMargin,
    fetchEstimate,
    fetchHistory,
  } = props;

  const [logout] = useLogout();
  const { modal, user, toast } = useContext(Context);
  const [step, setStep] = useState(1);

  const [managerList, setManagerList] = useState([]);
  const [lawyerList, setLawyerList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [contractorList, setContractorList] = useState([]);
  const [allLegalEntity, setAllLegalEntity] = useState([]);

  useEffect(() => {
    if (show) {
      check()
        .then(() => {
          fetchStaff({ job: 1, is_active: true }).then((data) => setLawyerList(data));

          fetchStaff({ job: 2, is_active: true }).then((data) => setDocumentList(data));

          fetchStaff({ job: 0, is_active: true }).then((data) => setManagerList(data));

          getBloggersInContract(bid_id).then((data) => setContractorList(data));

          autocompleteClientLegalEntities().then((data) => setAllLegalEntity(data));
        })
        .catch((error) => logout(error));
    }
  }, [show]);

  const [firstStepValues, setFirstStepValues] = useState({
    client: {},
    date_start: '',
    date_end: '',
  });

  const updateFirstStepValues = (fieldName, value) => {
    setFirstStepValues({ ...firstStepValues, [fieldName]: value });
  };

  const [step2Value, setStep2Value] = useState([
    {
      id: Date.now(),
      sk_bid_type: 7,
      is_contract: true,
      blogger: {
        id: null,
        name: '',
        legal_entity: [],
      },
      contractor_legal_entity: null,
      client_legal_entity: null,
      payment: {
        client_sum: '',
        client_sum_without_tax: '',
        is_client_nds: false,
        total_sum: '',
        ak_percent: '',
        payment_method: '',
        payment_dates: [],
        is_nds: false,
      },
      client_contacts: [],
      lawyer: [{ fieldId: Date.now(), id: null, name: '' }],
      doc_manager: [{ fieldId: Date.now(), id: null, name: '' }],
      manager: [{ fieldId: Date.now(), id: null, name: '' }],
      staff_contacts: [{ fieldId: Date.now(), id: null, name: '' }],
      files: [],
    },
  ]);

  const addSkBid = () => {
    check()
      .then(() => {
        const sk_bid = [];
        step2Value.forEach((element) => {
          sk_bid.push({ id: element.id });
        });

        const newSKBid = { type: { id: 7 } };

        if (step2Value[0]?.manager[0]?.id) {
          newSKBid.manager = [{ id: step2Value[0]?.manager[0]?.id }];
        }

        if (step2Value[0]?.lawyer[0]?.id) {
          newSKBid.lawyer = [{ id: step2Value[0]?.lawyer[0]?.id }];
        }

        if (step2Value[0]?.doc_manager[0]?.id) {
          newSKBid.doc_manager = [{ id: step2Value[0]?.doc_manager[0]?.id }];
        }

        if (step2Value[0]?.staff_contacts?.length > 0) {
          const tempArray = [];
          step2Value[0]?.staff_contacts?.forEach((element) => {
            tempArray.push({
              id: element.id,
            });
          });

          newSKBid.staff_contacts = tempArray;
        }

        sk_bid.push(newSKBid);

        const createData = { project: { id: dataProps.project.id }, sk_bid };
        patchSKBid(dataProps.id, createData).then(() => {
          getSKBid(dataProps.id).then((data) => {
            const tempArray = JSON.parse(JSON.stringify(data.sk_bid));

            tempArray.forEach((element) => {
              if (!element.blogger) {
                element.blogger = {
                  id: null,
                  name: '',
                  legal_entity: [],
                };
              }

              const dates = [];
              element.payments.forEach((date) => {
                dates.push({ ...date, sum: numberToSum(date.sum) });
              });

              element.sk_bid_type = element?.type?.id;

              element.payment = {
                client_sum: numberToSum(
                  element.client_sum,
                ),
                client_sum_without_tax: numberToSum(
                  element.client_sum_without_tax,
                ),
                is_client_nds: element.is_client_nds,
                total_sum: numberToSum(element.total_sum),
                ak_percent: numberToSum(element.ak_percent),
                payment_dates: dates,
                is_nds: element.is_nds,
                payment_method: element.payment_method,
              };

              element.manager = [
                {
                  fieldId: Date.now(),
                  id: user.user.staff.id,
                  fullname: user.user.staff.fullname,
                },
              ];
            });
            if (step2Value.length === 0) {
              setStep2Value(tempArray);
            } else {
              const results = tempArray.filter(
                ({ id: id1 }) => !step2Value.some(({ id: id2 }) => id2 === id1),
              );
              setStep2Value([...step2Value, ...results]);
            }
          });
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          onHide();
        }

        logout(err);
      });
  };

  useEffect(() => {
    if (dataProps && show) {
      setFirstStepValues({
        client: dataProps.project.client,
        date_start: dataProps.date_start,
        date_end: dataProps.date_end,
      });

      if (dataProps.sk_bid.length === 0) {
        check()
          .then(() => {
            const createData = {
              project: { id: dataProps.project.id },
              sk_bid: [{ type: { id: 7 } }],
            };
            patchSKBid(dataProps.id, createData).then(() => {
              getSKBid(dataProps.id).then((data) => {
                modal.setModalApplicationSKProps({
                  dataProps: data,
                  fetchProject,
                  ourLegalEntity,
                  bid_id,
                  client_legal_entity: data.project.client.legal_entity,
                  client_contacts: data.project.client.contacts,
                });
              });
            });
          })
          .catch((error) => logout(error));
      } else {
        const tempArray = JSON.parse(JSON.stringify(dataProps.sk_bid));
        tempArray.forEach((element) => {
          const dates = [];

          if (!element.blogger) {
            element.blogger = {
              id: null,
              name: '',
              legal_entity: [],
            };
          }
          element.payments.forEach((date) => {
            dates.push({ ...date, sum: numberToSum(date.sum) });
          });

          element.sk_bid_type = element?.type?.id;

          element.payment = {
            client_sum: numberToSum(
              element.client_sum,
            ),
            client_sum_without_tax: numberToSum(
              element.client_sum_without_tax,
            ),
            is_client_nds: element.is_client_nds,
            total_sum: numberToSum(element.total_sum),
            ak_percent: numberToSum(element.ak_percent),
            payment_dates: dates,
            is_nds: element.is_nds,
            payment_method: element.payment_method,
          };

          if (element.manager.length === 0) {
            element.manager = [
              {
                fieldId: Date.now(),
                id: user.user.staff.id,
                fullname: user.user.staff.fullname,
              },
            ];
          }

          if (element.staff_contacts.length > 0) {
            const tempStaff = [];
            element.staff_contacts.forEach((element) => {
              tempStaff.push({
                fieldId: Date.now() + element.id,
                id: element.id,
                name: element.fullname,
              });
            });

            element.staff_contacts = tempStaff;
          }
        });
        setStep2Value(tempArray);
      }
    }
  }, [dataProps, show]);

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setStep2Value([
          {
            id: Date.now(),
            sk_bid_type: 7,
            is_contract: true,
            blogger: {
              id: null,
              name: '',
              legal_entity: [],
            },
            contractor_legal_entity: null,
            client_legal_entity: null,
            payment: {
              client_sum: '',
              client_sum_without_tax: '',
              is_client_nds: false,
              total_sum: '',
              ak_percent: '',
              payment_method: '',
              payment_dates: [],
              is_nds: false,
            },
            client_contacts: [],
            lawyer: [{ fieldId: Date.now(), id: null, name: '' }],
            doc_manager: [{ fieldId: Date.now(), id: null, name: '' }],
            manager: [{ fieldId: Date.now(), id: null, name: '' }],
            staff_contacts: [{ fieldId: Date.now(), id: null, name: '' }],
            files: [],
          },
        ]);
      }, 200);
    }
  }, [show]);

  const deletePayment = (id, event) => {
    event.stopPropagation();
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите удалить оплату?',
      click: () => {
        check().then(() => {
          deletePaymentBID(id);
          setStep2Value(step2Value.filter((item) => item.id !== id));
        });
      },
    });
    modal.setModalConfirmVisible(true);
  };

  const saveToDraftHandler = () => {
    check()
      .then(() => {
        const sentPayments = [];

        step2Value.forEach((value) => {
          const {
            blogger,
            files,
            contractor_legal_entity,
            client_legal_entity,
            lawyer,
            doc_manager,
            manager,
            staff_contacts,
            client_contacts,
            sk_bid_type,
            payment,
            ...sent
          } = value;

          if (Number.isInteger(blogger.id)) {
            sent.blogger = { id: blogger.id };
          }

          // Особенность СК - наше юр лицо, выступает как клиент
          if (Number.isInteger(client_legal_entity?.id)) {
            sent.client_legal_entity = { id: client_legal_entity?.id };
          }

          if (Number.isInteger(contractor_legal_entity?.id)) {
            sent.contractor_legal_entity = { id: contractor_legal_entity?.id };
          }

          const sentPaymentDates = [];

          payment.payment_dates.forEach((element) => {
            if (element?.date) {
              if (element?.id >= 0) {
                sentPaymentDates.push({
                  id: element.id,
                  date: element.date ? getDate(element.date) : '',
                  sum: getSum(element.sum),
                  responsible: element?.responsible?.id
                    ? { id: element?.responsible?.id }
                    : { id: manager[0]?.id },
                });
              } else {
                sentPaymentDates.push({
                  date: element.date ? getDate(element.date) : '',
                  sum: getSum(element.sum),
                  responsible: element?.responsible?.id
                    ? { id: element?.responsible?.id }
                    : { id: manager[0]?.id },
                });
              }
            } else {
              toast.setToastInfo('Не выбрана дата оплаты');
              toast.setShowError(true);
            }
          });
          sent.payments = sentPaymentDates;

          sent.client_sum = getSum(payment.client_sum);
          sent.is_client_nds = (payment.is_client_nds);
          sent.total_sum = getSum(payment.total_sum);
          sent.ak_percent = getSum(payment.ak_percent);
          sent.is_nds = (payment.is_nds);
          sent.payment_method = (payment.payment_method);
          sent.type = { id: sk_bid_type };

          const sentLawyers = [];
          const sentDocuments = [];
          const sentStaffContacts = [];

          lawyer.forEach((element) => {
            if (element.id) {
              sentLawyers.push({ id: element.id });
            }
          });
          doc_manager.forEach((element) => {
            if (element.id) {
              sentDocuments.push({ id: element.id });
            }
          });
          staff_contacts.forEach((element) => {
            if (element.id) {
              sentStaffContacts.push({ id: element.id });
            }
          });

          sent.lawyer = sentLawyers;
          sent.doc_manager = sentDocuments;
          if (manager[0].id) {
            sent.manager = [{ id: manager[0].id }];
          }
          sent.staff_contacts = sentStaffContacts;

          const sentContacts = [];

          client_contacts.forEach((element) => {
            if (element.id < 1000000000) {
              sentContacts.push({ ...element });
            }
          });

          sent.client_contacts = sentContacts;

          sentPayments.push(sent);
        });

        const sentData = {
          project: {
            id: dataProps.project.id,
          },
          sk_bid: sentPayments,
          date_start: getDate(firstStepValues.date_start),
          date_end: getDate(firstStepValues.date_end),
        };

        patchSKBid(dataProps.id, sentData).then(() => {
          toast.setToastInfo('Черновик сохранен');
          toast.setShowSuccess(true);
          onHide();
        }).catch((err) => {
          toast.setToastInfo('Обратитесь в техподдержку');
          toast.setShowError(true);
          console.log(err);
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          onHide();
        }

        logout(err);
      });
  };

  const sendDraft = () => {
    check()
      .then(() => {
        const sentPayments = [];

        let isError = false;
        let errorText = '';

        step2Value.forEach((value) => {
          const {
            blogger,
            files,
            contractor_legal_entity,
            client_legal_entity,
            lawyer,
            doc_manager,
            manager,
            staff_contacts,
            client_contacts,
            payment,
            sk_bid_type,
            ...sent
          } = value;

          if (!blogger?.id) {
            isError = true;
            errorText = 'Выберите заказчика';
          }

          // if (sent.is_contract) {
          //   if (!contractor_legal_entity?.id) {
          //     isError = true;
          //     errorText = 'Выберите ЮЛ исполнителя';
          //   }

          //   if (!client_legal_entity?.id) {
          //     isError = true;
          //     errorText = 'Выберите наше ЮЛ';
          //   }
          // }

          if (Number.isInteger(blogger.id)) {
            sent.blogger = { id: blogger.id };
          }

          if (Number.isInteger(client_legal_entity?.id)) {
            sent.client_legal_entity = { id: client_legal_entity?.id };
          } else {
            sent.client_legal_entity = null;
          }

          if (Number.isInteger(contractor_legal_entity?.id)) {
            sent.contractor_legal_entity = { id: contractor_legal_entity?.id };
          } else {
            sent.contractor_legal_entity = null;
          }

          const sentPaymentDates = [];
          let totalPaymentSum = 0;

          payment.payment_dates.forEach((element) => {
            totalPaymentSum += getSum(element.sum);
            if (element?.date) {
              if (element?.id >= 0) {
                sentPaymentDates.push({
                  id: element.id,
                  date: element.date ? getDate(element.date) : '',
                  sum: getSum(element.sum),
                  responsible: element?.responsible?.id
                    ? { id: element?.responsible?.id }
                    : { id: manager[0]?.id },
                });
              } else {
                sentPaymentDates.push({
                  date: element.date ? getDate(element.date) : '',
                  sum: getSum(element.sum),
                  responsible: element?.responsible?.id
                    ? { id: element?.responsible?.id }
                    : { id: manager[0]?.id },
                });
              }
            } else {
              isError = true;
              errorText = 'Не выбрана дата оплаты';
            }
          });
          sent.payments = sentPaymentDates;

          if (step === 2) {
            if (totalPaymentSum > getSum(payment.total_sum)) {
              isError = true;
              errorText = 'Сумма оплаты превышает ранее введенную сумму СК';
            } else if (totalPaymentSum < getSum(payment.total_sum)) {
              isError = true;
              errorText = 'Сумма оплаты меньше ранее введенной суммы СК';
            }
          }

          sent.client_sum = getSum(payment.client_sum);
          sent.is_client_nds = (payment.is_client_nds);
          sent.total_sum = getSum(payment.total_sum);
          sent.ak_percent = getSum(payment.ak_percent);
          sent.is_nds = (payment.is_nds);
          sent.payment_method = (payment.payment_method);
          sent.type = { id: sk_bid_type };

          if (sent.ak_percent > 100) {
            isError = true;
            errorText = 'Процент СК не может быть больше 100';
          }

          const sentLawyers = [];
          const sentDocuments = [];
          const sentStaffContacts = [];

          lawyer.forEach((element) => {
            if (element.id) {
              sentLawyers.push({ id: element.id });
            }
          });
          doc_manager.forEach((element) => {
            if (element.id) {
              sentDocuments.push({ id: element.id });
            }
          });
          staff_contacts.forEach((element) => {
            if (element.id) {
              staff_contacts.push({ id: element.id });
            }
          });

          sent.lawyer = sentLawyers;
          sent.doc_manager = sentDocuments;
          sent.manager = [{ id: manager[0].id }];
          sent.staff_contacts = sentStaffContacts;

          const sentContacts = [];

          client_contacts.forEach((element) => {
            if (element.id < 1000000000) {
              sentContacts.push({ ...element });
            }
          });

          sent.client_contacts = sentContacts;

          sentPayments.push(sent);
        });

        if (isError) {
          toast.setToastInfo(errorText);
          toast.setShowError(true);
          return;
        }

        const sentData = {
          project: {
            id: dataProps.project.id,
          },
          sk_bid: sentPayments,
          date_start: getDate(firstStepValues.date_start),
          date_end: getDate(firstStepValues.date_end),
        };

        putSKBid(dataProps.id, sentData).then(() => {
          onHide();
          toast.setToastInfo('Заявка отправлена');
          toast.setShowSuccess(true);
          fetchProject('application');
          fetchMargin();
          fetchEstimate();
          fetchHistory();
        }).catch((err) => {
          toast.setToastInfo('Обратитесь в техподдержку');
          toast.setShowError(true);
          console.log(err);
        });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          onHide();
        }

        logout(err);
      });
  };

  const nextStep = () => {
    if (step >= 2) {
      return;
    }

    setStep(step + 1);
  };

  const backStep = () => {
    setStep((step) => --step);
  };

  const closeModal = () => {
    modal.setModalConfirmProps({
      text: 'Вы уверены, что хотите закрыть заявку?',
      click: () => {
        onHide();
      },
    });
    modal.setModalConfirmVisible(true);
  };

  return (
    <>
      <ModalConfirm
        show={modal.modalConfirmVisible}
        onHide={() => modal.setModalConfirmVisible(false)}
        props={modal.modalConfirmProps}
      />

      <MyModal show={show} onHide={closeModal} style={{ minHeight: 500 }}>
        <div className={classes.wrapper}>
          <div>
            {step === 1 && (
              <>
                <h1 className={classes.header}>Заявка на СК</h1>

                <div className={classes.headerMini}>
                  Шаг 1. Общая информация
                </div>

                <div className={classes.payment__container}>
                  <Step1
                    firstStepValues={firstStepValues}
                    updateFirstStepValues={updateFirstStepValues}
                    id={dataProps?.project?.project_id}
                    name={dataProps?.project?.name}
                    adv_object={dataProps?.adv_object}
                    adv_object_comment={dataProps?.adv_object_comment}
                  />
                </div>
              </>
            )}

            {step === 2 && (
              <>
                <div className={classes.btnBack}>
                  <SuccessBtn
                    text="Вернуться назад"
                    onClick={backStep}
                    type="white"
                    back
                    style={{
                      width: 142,
                      padding: '2px 10px',
                      fontSize: '12px',
                      marginRight: 40,
                    }}
                  />
                  <span className={classes.headerMini}>Шаг 2. Оплата</span>
                </div>

                <div className={classes.payment__container}>
                  <div>
                    {step2Value.map((element, index) => (
                      <Step2
                        key={element.id}
                        ourLegalEntity={ourLegalEntity}
                        contractorList={contractorList}
                        client_contacts={client_contacts}
                        dataProps={dataProps}
                        show={show}
                        index={index}
                        element={element}
                        step2Value={step2Value}
                        setStep2Value={setStep2Value}
                        deletePayment={deletePayment}
                        managerList={managerList}
                        lawyerList={lawyerList}
                        documentList={documentList}
                        allLegalEntity={allLegalEntity}
                        onHide={onHide}
                        client={firstStepValues.client}
                      />
                    ))}
                  </div>

                  <Align justifyContent="space-between">
                    <div
                      style={{ width: 407, marginBottom: 31, marginLeft: 260 }}
                    >
                      <NewAddBtn text="Добавить оплату" onClick={addSkBid} />
                    </div>
                  </Align>
                </div>
              </>
            )}
          </div>

          <Align gap="15px" justifyContent="center">
            {!isSent && (
            <SuccessBtn
              text="Сохранить черновик"
              type="white"
              onClick={saveToDraftHandler}
              style={{ padding: '3px 15px', fontSize: 13 }}
            />
            )}

            {step === 2 ? (
              <SuccessBtn
                text={
                  isSent ? 'Заменить данные в договоре' : 'Отправить заявку'
                }
                onClick={sendDraft}
                style={{ padding: '3px 15px', fontSize: 13 }}
              />
            ) : (
              <SuccessBtn
                text="Продолжить"
                onClick={nextStep}
                style={{ padding: '3px 15px', fontSize: 13 }}
              />
            )}
          </Align>
        </div>
      </MyModal>
    </>
  );
});

export default ModalCreateSk;
