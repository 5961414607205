// @ts-nocheck
import React from 'react';

import InputElement from 'components/UI/InputElements/InputElement';
import ContractElement from 'components/UI/Modals/ModalCreateContract/components/ContractElement';

import { isValidURL } from 'utils/isValidUrl';

import PublicationInfoItem from './PublicationInfoItem';

import classes from '../../ProjectPage.module.scss';

function PublicationInfoElement(props) {
  const { media_channel, publicationTypes, textStyle } = props;

  return (
    <div className={classes.media_channel__wrapper}>

      <InputElement
        text="Ссылка"
        value={media_channel?.link}
        isLink={isValidURL(media_channel?.link)}
        disabled
        textStyle={textStyle}
      />

      {media_channel?.publications?.map((publication) => (
        <div key={publication.id}>
          <PublicationInfoItem
            publication={publication}
            publicationTypes={publicationTypes}
            textStyle={textStyle}
          />

          {publication?.subpublications?.map((subpublication) => (
            <PublicationInfoItem
              key={subpublication.id}
              publication={subpublication}
              publicationTypes={publicationTypes}
              textStyle={textStyle}
            />
          ))}
        </div>

      ))}

      <ContractElement
        text="Передача прав"
        textStyle={{
          minWidth: 230,
          width: 230,
          height: 32,
        }}
      >

        <div style={{ width: '100%' }}>

          <InputElement
            value={media_channel?.is_assignment
              ? 'Да'
              : 'Нет'}
            disabled
          />

          {media_channel?.is_assignment && (
            <>
              <InputElement
                value={media_channel?.assignment_type}
                disabled
                dropdown={[]}
              />

              <InputElement
                value={media_channel?.assignment_comment}
                placeholder="Комментарий (заполните в заявке)"
                disabled
              />

            </>
          )}

        </div>

      </ContractElement>

      <InputElement
        text="Разрешен ли репост"
        value={media_channel?.is_repost
          ? 'Да'
          : 'Нет'}
        placeholder="Комментарий (заполните в заявке)"
        disabled
        textStyle={textStyle}
      />

      {media_channel?.is_repost && (
        <InputElement
          value={media_channel?.repost_comment}
          placeholder="Комментарий (заполните в заявке)"
          disabled
          style={{ marginLeft: 230 }}
        />

      )}
    </div>
  );
}

export default PublicationInfoElement;
