/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from '@mui/material';
import { Context } from 'index';

import { approveCommentPatch } from 'API/ApplicationAPI';
import { patchOneCostBid } from 'API/FinanceAPI/CostsAPI';
import { patchClosingInfoProjectAccounting, patchContractAccounting } from 'API/FinanceAPI/ProjectAccounting';
import { check } from 'API/UserAPI';

import CheckBox from 'components/UI/MyCheckbox/CheckBox';
import QuarterDropdown from 'components/UI/QuarterDropdown/QuarterDropdown';
import StatusBtn from 'components/UI/StatusBtns/StatusBtn/StatusBtn';
import StatusDropdown from 'components/UI/StatusDropdown/StatusDropdown';

import { useLogout } from 'hooks/useLogout';

// @ts-ignore
import { PROJECT_ROUTE } from 'utils/consts';
import { checkFormat } from 'utils/getCurrentQuarter';
import { getDate, reversDate } from 'utils/getDate';
import { numberToSum } from 'utils/getSums';

// @ts-ignore
import calendar from '../../../../assets/images/calendar.svg';

import classes from '../RegistryTable.module.scss';

function RegistryTableRow(props) {
  const {
    columns,
    row,
    number,
    manager1,
    marketing,
    lawyers,
    costsFinance,
    report,
    staffCard,
    rowClassName,
    cellClassName,
    tableName,
    linkTo,
    noDeadline,
    projectAccounting,
    closingInfo,
    additionalRow,
    mainRow,
  } = props;

  const {
    contracts, projectsFinance, projects, user, reportProjects, reportTasks, reportNews, toast, modal,
  } = useContext(Context);

  const navigate = useNavigate();

  const checkComments = (commentsArray) => {
    // Определяем, какой массив комментариев использовать
    const commentsToCheck = commentsArray || row?.additional_comments;

    // Проверяем, является ли выбранный массив комментариев массивом
    if (Array.isArray(commentsToCheck)) {
      // Возвращаем false, если хотя бы один комментарий не одобрен и у пользователя есть доступ
      return !commentsToCheck.some((comment) => {
        // Проверяем, что комментарий не одобрен
        const isNotApproved = comment?.is_approve === false;
        // Проверяем, есть ли доступ у текущего пользователя
        const hasAccess = comment?.job_access?.some((access) => access.id === user.user.job.id);
        // Возвращаем true, если комментарий не одобрен и у пользователя есть доступ
        return isNotApproved && hasAccess;
      });
    }
    // Если передан не массив, возвращаем true
    return true;
  };

  // Учет проектов

  const [indicateRed, setIndicateRed] = useState(row?.closing_info?.filter((el) => el.indicate_red === true).length >= 1);
  const [additionalRowindicateRed, setAdditionalRowIndicateRed] = useState(closingInfo?.indicate_red || false);
  const [isDeletededPaymentApprove, setIsDeletedPaymentapprove] = useState(checkComments());
  // @ts-ignore
  const [indicateYellow, setIndicateYellow] = useState((row?.closing_info?.length > 0 && mainRow && row?.closing_info[0]?.indicate_yellow) || false);
  // @ts-ignore
  const [additionalRowindicateYellow, setAdditionalRowIndicateYellow] = useState((closingInfo?.indicate_yellow) || false);
  const [incomeDate, setIncomeDate] = useState([]);
  const [paymentsDate, setPaymentsDate] = useState([]);
  const [accountingDate, setAccountingDate] = useState(null);
  const [additionalComments, setAdditionalComments] = useState([]);

  // Учет проектов

  let overdue = false;

  const [logout] = useLogout();

  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const [anchorElAlt, setAnchorElAlt] = React.useState(null);
  const handlePopoverOpenAlt = (event) => {
    setAnchorElAlt(event.currentTarget);
  };
  const handlePopoverCloseAlt = () => {
    setAnchorElAlt(null);
  };
  const openAlt = Boolean(anchorElAlt);

  const [anchorElProject, setAnchorElProject] = React.useState(null);
  const handlePopoverOpenProjectName = (event) => {
    setAnchorElProject(event.currentTarget);
  };
  const handlePopoverCloseProjectName = () => {
    setAnchorElProject(null);
  };
  const openProjectName = Boolean(anchorElProject);

  const [anchorGL, setAnchorGL] = React.useState(null);
  const handlePopoverOpenGL = (event) => {
    setAnchorGL(event.currentTarget);
  };
  const handlePopoverCloseGL = () => {
    setAnchorGL(null);
  };
  const openGL = Boolean(anchorGL);
  // ФУНКЦИИ ДЛЯ РАБОТЫ ВСПЫВАЮЩЕЙ ПОДСКАЗКИ

  if (row.deadline) {
    overdue = getDate(row.deadline) <= getDate(Date.now());
  } else if (row.date_end) {
    overdue = getDate(row.date_end) <= getDate(Date.now());
  }

  const classNames = [
    classes.row,
    overdue && !noDeadline && classes.highlighted,
    // Учет проектов
    mainRow && indicateRed && classes.highlighted,
    additionalRow && additionalRowindicateRed && classes.highlighted,
    indicateYellow && classes.highlighted__yellow,
    additionalRowindicateYellow && classes.highlighted__yellow,
    ((mainRow || additionalRow) && (user.user.role.id !== 2 || user.user.department.id !== 2)) && classes.no__cursor,
    // Документы
    !isDeletededPaymentApprove && classes.highlighted,
    row?.project?.validate_margin === 'Не корректное распределение маржи' && classes.highlighted,
    row?.project?.validate_margin === 'Пустое распределение маржи' && classes.highlighted__yellow,
    row?.validate_contract === 'Корректные статусы договора' && classes.highlighted__green,
    row?.validate_contract === 'Частично заполненный договор' && classes.highlighted__yellow,
    rowClassName,
  ].join(' ');

  const openOnePageHandler = (event) => {
    if (tableName === 'projectAccounting') {
      event.preventDefault();
      if ((user.user.role.id !== 2 || user.user.department.id === 2 || user.user.department.id === 3)) {
        if (mainRow) {
          if (row.closing_info?.length > 1 && (row?.type !== 'КЛ (у)' && row?.type !== 'КЛ (а)' && row?.type !== 'СК доход')) {
            console.log('');
          } else if (row.closing_info?.length > 0) {
            modal.setModalEditRowProps({
              row,
              isMainRow: true,
              closingInfo,
              setIncomeDate,
              setPaymentsDate,
              setAccountingDate,
            });
            modal.setModalEditRowVisible(true);
          }

          // modal.setModalEditRowProps({
          //   row,
          //   isMainRow: true,
          //   closingInfo,
          //   setIncomeDate,
          //   setPaymentsDate,
          //   setAccountingDate,
          // });
          // modal.setModalEditRowVisible(true);
        }

        if (row.closing_info?.length > 1 && additionalRow) {
          modal.setModalEditRowProps({
            row,
            // isMainRow: true,
            closingInfo,
            setIncomeDate,
            setPaymentsDate,
            setAccountingDate,
          });
          modal.setModalEditRowVisible(true);
        }
      } else {
        navigate(`${PROJECT_ROUTE}/${row?.project?.id}`);
      }
      // if (mainRow) {
      //   event.preventDefault();
      //   if ((user.user.role.id !== 2 || user.user.department.id === 2 || user.user.department.id === 3) && (row?.type === 'КЛ (у)' || row?.type === 'КЛ (а)' || row?.type === 'СК доход')) {
      //     modal.setModalEditRowProps({
      //       row,
      //       isMainRow: true,
      //       closingInfo,
      //       setIncomeDate,
      //       setPaymentsDate,
      //       setAccountingDate,
      //     });
      //     modal.setModalEditRowVisible(true);
      //   } else {
      //     navigate(`${PROJECT_ROUTE}/${row?.project?.id}`);
      //   }
      // }

      // if (additionalRow) {
      //   event.preventDefault();
      //   if (user.user.role.id !== 2 || user.user.department.id === 2 || user.user.department.id === 3) {
      //     modal.setModalEditRowProps({
      //       row,
      //       isMainRow: false,
      //       closingInfo,
      //       setIncomeDate,
      //       setPaymentsDate,
      //       setAccountingDate,
      //     });
      //     modal.setModalEditRowVisible(true);
      //   } else {
      //     navigate(`${PROJECT_ROUTE}/${row?.project?.id}`);
      //   }
      // }
    }

    if (tableName === 'costsFinance') {
      projectsFinance.setCostsPage(1);
      projectsFinance.setCosts([]);
    } else if (tableName === 'projects' || tableName === 'projectsManager') {
      contracts.setContractsPage(1);
      contracts.setContracts([]);
    } else if (tableName === 'projects_alternative') {
      if (user.department === 1 || user.department === 2) {
        event.preventDefault();
        return;
      }

      projects.setProjects([], 'update');
      projects.setProjectsPage(1);
      projects.setProjectsFilter(undefined);
    } else if (tableName === 'reportProjects') {
      reportProjects.setReportProjectsPage(1);
      reportProjects.setReportProjects([]);

      reportTasks.setReportTasksPage(1);
      reportTasks.setReportTasks([]);

      reportNews.setReportNewsPage(1);
      reportNews.setReportNews([]);
    }
  };

  const [isPP, setIsPP] = useState(row?.is_pp || false);

  const changePPHandler = (e) => {
    e.stopPropagation();

    check()
      .then(() => {
        patchOneCostBid(row.id, { is_pp: !isPP }).then(() => setIsPP(!isPP)).catch((error) => {
          toast.setToastInfo('Нельзя активировать чек-бокс \'Нужно ПП\' при наличии у заявки статуса \'Оплачено\' или \'Частичная оплата\'');
          toast.setShowError(true);
        });
      })
      .catch((error) => logout(error));
  };

  useEffect(() => {
    if (costsFinance || lawyers) {
      setAdditionalComments(row?.additional_comments);
    }
  }, [costsFinance, lawyers, row?.additional_comments]);

  // Учет проектов

  const [comments, setComments] = useState([]);
  const [additionalRowComments, setAdditionalRowComments] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAdditionalRowExpanded, setIsAdditionalRowExpanded] = useState(false);
  const [quarter, setQuarter] = useState('-');
  const [status, setStatus] = useState(0);
  const [isAdesk, setIsAdesk] = useState(row?.adesk || false);

  useEffect(() => {
    if (mainRow) {
      setStatus(row?.accounting_status?.id);
    } else if (additionalRow) {
      setStatus(closingInfo?.accounting_type_id);
    }
  }, []);

  useEffect(() => {
    if (mainRow) {
      setQuarter(checkFormat(row?.closing_info[0]?.quarter));
    } else if (additionalRow) {
      setQuarter(checkFormat(closingInfo?.quarter));
    }
  }, []);

  useEffect(() => {
    let combinedDates = [];

    if (row?.income_dates) {
      combinedDates = [...row.income_dates];
    }

    if (Array.isArray(row?.payment_dates) && row.payment_dates.length > 0) {
      const flatPaymentDates = row.payment_dates.flat();
      combinedDates = [...combinedDates, ...flatPaymentDates];
    }

    setIncomeDate(combinedDates);
  }, [row?.income_dates, row?.payment_dates]);

  useEffect(() => {
    setPaymentsDate(row?.payments);
  }, [row?.payments]);

  useEffect(() => {
    setAccountingDate({ date: closingInfo?.date, sum: closingInfo?.sum });
  }, [closingInfo]);

  useEffect(() => {
    if (row?.closing_info && row?.closing_info[0]?.comment) {
      setComments(row?.closing_info[0]?.comment);
    }
  }, [row?.closing_info]);

  useEffect(() => {
    if (closingInfo) {
      setAdditionalRowComments(closingInfo.comment);
    }
  }, [closingInfo]);

  const numberOfAdditionalComments = comments && comments?.length - 1;
  const numberOfAdditionalRowComments = additionalRowComments && additionalRowComments?.length - 1;
  const numberOfAdditionalCommentsCost = additionalComments && additionalComments?.length - 1;

  const changeAdeskHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (user.user.role.id === 2 || user.user.department.id === 2 || user.user.department.id === 3) {
      check()
        .then(() => {
          patchContractAccounting(row.id, { adesk: !isAdesk });
          setIsAdesk(!isAdesk);
        })
        .catch((error) => logout(error));
    }
  };

  const removeAllComments = (e, id, isClosingInfo) => {
    e.preventDefault();
    e.stopPropagation();
    check()
      .then(() => {
        patchClosingInfoProjectAccounting(id, { comment: [] });
        if (isClosingInfo) {
          setAdditionalRowComments([]);
          setAdditionalRowIndicateRed(!additionalRowindicateRed);
          setIndicateRed(false);
        } else {
          setComments([]);
          setIndicateRed(!indicateRed);
        }
        toast.setToastInfo('Все изменения приняты');
        toast.setShowSuccess(true);
      })
      .catch((error) => logout(error));
  };

  const approveComment = (id) => {
    approveCommentPatch(id)
      .then(() => {
        const updatedComments = additionalComments.map((comment) => {
          if (comment.id === id) {
            return { ...comment, is_approve: true };
          }
          return comment;
        });

        setAdditionalComments(updatedComments);

        setIsDeletedPaymentapprove(checkComments(updatedComments));
      });
    setIsDeletedPaymentapprove(true);
  };

  // Учет проектов

  return (
    <div
      className={classes.container}
      style={number <= 14 ? { position: 'static' } : {}}
    >
      <Link
        to={tableName === 'reportProjects' ? `${linkTo}/${row?.project?.id}` : `${linkTo}/${row?.id}`}
        className={classNames}
        onClick={(event) => openOnePageHandler(event)}
      >
        {!additionalRow ? (
          <div className={[classes.cell, cellClassName].join(' ')}>
            {row?.project?.project_id || row?.project_id || '-'}
          </div>
        ) : (
          <div className={[classes.cell, cellClassName].join(' ')} />
        )}
        {Array.isArray(columns)
          && columns.map((c) => (
            <div key={c?.id} className={[classes.cell, cellClassName].join(' ')}>
              {manager1 && (
                <>
                  {c.id === 'client'
                  && row?.client?.name}
                  {c.id === 'title'
                  && (
                    <>
                      <p
                        style={{ margin: 0 }}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {row?.name}
                      </p>
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={row?.name?.length >= 33 ? open : false}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableAutoFocus
                        disableEnforceFocus
                      >
                        <div className={classes.popoverText}>{row?.name}</div>
                      </Popover>
                    </>
                  )}
                  {c.id === 'created' && reversDate(row?.created)}
                  {c.id === 'manager' && row?.manager.length > 0 && row?.manager[0]?.fullname}
                  {c.id === 'director' && row?.director.length > 0 && row?.director[0]?.fullname}
                  {c.id === 'responsible' && row?.responsible.length > 0 && row?.responsible[0]?.fullname}

                  {c.id === 'status' && (
                    <StatusDropdown
                      status={row?.status?.id}
                      id={row?.id}
                      manager
                    />
                  )}
                </>
              )}

              {staffCard && (
                <>
                  {c.id === 'title' && row.project?.name}

                  {c.id === 'type' && row[c.id]?.name}

                  {c.id === 'status' && (
                    <StatusBtn status={row.project?.status?.id} lawyer />
                  )}

                  {c.id === 'contractorLegalEntity'
                    && row.contractor_legal_entity?.legal_name}

                  {c.id === 'clientLegalEntity'
                    && row.client_legal_entity?.legal_name}
                </>
              )}

              {marketing && (
                <>
                  {c.id === 'title' && row?.name}

                  {c.id === 'period' && (
                  <div className={classes.period__container}>
                    <div className={classes.period__container__inner}>
                      <img src={calendar} alt="calendar" />
                      <div>{reversDate(row?.contract.date_start)}</div>
                    </div>

                    <div className={classes.period__container__inner}>
                      <img src={calendar} alt="calendar" />
                      <div>{reversDate(row?.contract.date_end)}</div>
                    </div>
                  </div>
                  )}

                  {c.id === 'payment_date' && (
                  <div className={classes.paymentDate__container}>
                    {row?.contract.payments.map((payment_date) => (

                      <div key={payment_date.id} className={classes.paymentDate__container__inner}>
                        {payment_date.date && <img src={calendar} alt="calendar" />}
                        <div>{payment_date.date ? reversDate(payment_date.date) : '-'}</div>
                      </div>

                    ))}
                  </div>
                  )}

                  {c.id === 'sum' && (
                  <div className={classes.paymentSum__container}>
                    {row?.contract.payments.map((payment_date) => (

                      <div key={payment_date.id}>
                        <div>{payment_date.sum ? numberToSum(payment_date.sum) : '-'}</div>
                      </div>

                    ))}
                  </div>
                  )}

                  {c.id === 'paid_sum' && (row?.contract.paid_sum ? numberToSum(row?.contract.paid_sum) : 0)}

                  {c.id === 'credit' && (row?.contract.credit ? numberToSum(row?.contract.credit) : 0)}

                  {c.id === 'role' && (row?.blogger_role || row?.client_role)}

                  {c.id === 'created' && reversDate(row?.created)}

                  {c.id === 'manager' && row?.manager.length > 0 && row?.manager[0]?.fullname}

                  {c.id === 'responsible' && row?.responsible.length > 0 && row?.responsible[0]?.fullname}

                  {c.id === 'status' && (
                    <StatusBtn
                      status={row.status.id}
                      marketing
                      style={{ padding: '5px 10px' }}
                    />
                  )}
                </>
              )}

              {lawyers && (
                <>
                  {c.id === 'type' && row[c.id]?.name}

                  {c.id === 'client'
                    && (
                      (row?.is_contract === false && (row?.type.id === 2 || row?.type.id === 7) && 'Физическая оплата')
                    || (
                      row?.is_contract === false && (row?.type.id === 0 || row?.type.id === 1 || row?.type.id === 4) && `${row?.project?.client?.name}`)
                    || (row?.client_legal_entity?.legal_name || 'Неизвестно'))}

                  {c.id === 'contractor'
                    && (
                      (row?.is_contract === false && (row?.type.id === 2 || row?.type.id === 7) && `${row?.project?.blogger?.name}`)
                    || (
                      row?.is_contract === false && (row?.type.id === 0 || row?.type.id === 1 || row?.type.id === 4) && 'Физическая оплата')
                    || (row?.contractor_legal_entity?.legal_name || 'Неизвестно'))}

                  {c.id === 'lawyer'
                    && row?.lawyer
                    && row?.lawyer.length > 0
                    && row?.lawyer[0]?.fullname}

                  {c.id === 'doc_manager' && row?.doc_manager && row?.doc_manager.length > 0 && row?.doc_manager[0]?.fullname}

                  {c.id === 'status' && (
                    <StatusDropdown
                      id={row?.id}
                      projectId={row?.project.id}
                      status={row?.lawyer_status?.id}
                      btnStyle={{ padding: '5px 14px' }}
                      lawyer
                    />
                  )}

                  {c.id === 'marking_data' && row[c.id]}

                  {c.id === 'payment_date' && (
                  <div className={classes.paymentDate__container}>
                    {row?.payments?.map((payment_date) => (

                      <div key={payment_date.id} className={classes.sumWithDate__container}>
                        <div key={payment_date.id} className={classes.paymentDate__container__inner}>
                          {payment_date?.date && <img src={calendar} alt="calendar" />}
                          <div>{payment_date?.date ? reversDate(payment_date.date) : '-'}</div>

                        </div>

                        <div>{numberToSum(payment_date?.sum, true)}</div>
                      </div>

                    ))}
                  </div>
                  )}

                  {c.id === 'paid_sum' && numberToSum(row?.paid_sum, true)}

                  {c.id === 'credit' && numberToSum(row?.credit, true)}

                  {c.id === 'comment' && row?.additional_comments?.length > 0 && (

                    additionalComments.map((comment) => (
                      <div key={comment.text} className={classes.commentRow}>
                        <div className={classes.commentText}>
                          {comment.text}
                        </div>
                        {!comment?.is_approve && comment?.job_access?.some((access) => access.id === user.user.job.id)
    && (
    <div className={classes.comment__container__icon}>
      <div className={classes.comment__container__icon__icon} onClick={(e) => { e.preventDefault(); e.stopPropagation(); approveComment(comment.id); }} />
    </div>
    )}

                      </div>

                    ))
                  )}

                </>
              )}

              {costsFinance && (
                <>
                  {c.id === 'title' && row?.project?.name}

                  {c.id === 'client_legal_entity' && (row?.client_legal_entity || 'Неизвестно')}

                  {c.id === 'contractor_legal_entity' && (row?.contractor_legal_entity || 'Неизвестно')}
                  {c.id === 'comment' && additionalComments?.length > 0 && (
                  <div className={classes.comment}>
                    <div className={classes.comment__container}>
                      <div className={classes.comment__container__cost}>
                        {additionalComments && additionalComments[0]?.text}
                        {!additionalComments[0]?.is_approve && additionalComments[0]?.job_access?.some((access) => access.id === user.user.job.id)
                        && (
                        <div style={{ marginLeft: 3 }} className={classes.comment__container__icon}>
                          <div className={classes.comment__container__icon__icon} onClick={(e) => { e.preventDefault(); e.stopPropagation(); approveComment(additionalComments[0].id); }} />
                        </div>
                        )}

                      </div>
                      {isExpanded ? (
                        additionalComments?.slice(1).map((com) => (
                          <div key={com.id} className={classes.comment__container__cost}>
                            {com.text}
                            {!com?.is_approve && com?.job_access?.some((access) => access.id === user.user.job.id)
                        && (
                        <div style={{ marginLeft: 3 }} className={classes.comment__container__icon}>
                          <div className={classes.comment__container__icon__icon} onClick={(e) => { e.preventDefault(); e.stopPropagation(); approveComment(com.id); }} />
                        </div>
                        )}
                          </div>
                        ))
                      ) : null}
                      {numberOfAdditionalCommentsCost > 0 && (
                      <button
                        className={classes.comment__container__button}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsExpanded(!isExpanded);
                        }}
                      >
                        <div className={classes.comment__container__button__text}>
                          {isExpanded ? 'Скрыть изменения' : `+ Еще ${numberOfAdditionalCommentsCost} изменений`}
                        </div>
                      </button>
                      )}
                    </div>
                  </div>
                  )}

                  {c.id === 'manager' && (row?.responsible?.fullname || 'Неизвестно')}

                  {c.id === 'doc' && (row?.doc_manager?.fullname || 'Неизвестно')}

                  {c.id === 'purpose' && row?.purpose}

                  {c.id === 'id' && row?.id}

                  {c.id === 'type' && row?.type}

                  {c.id === 'contract_status' && row?.contract_status}

                  {c.id === 'is_pp' && row?.type === 'По договору' && (
                    <span onClick={changePPHandler}>
                      <CheckBox
                        className={classes.checkboxPP}
                        checked={isPP}
                      />
                    </span>

                  )}

                  {c.id === 'date' && (row?.payment_dates?.length > 0 ? <div>{row?.payment_dates?.map((el, index) => <p key={index} style={{ margin: 0 }}>{reversDate(el?.date)}</p>) }</div> : reversDate(row?.date)) }

                  {c.id === 'sum' && (row?.payment_dates?.length > 0 ? <div>{row?.payment_dates?.map((el, index) => <p key={index} style={{ margin: 0 }}>{numberToSum(el.sum)}</p>) }</div> : numberToSum(row.sum)) }

                  {c.id === 'deadline' && row?.payment_dates && row?.payment_dates.length > 0
                    && reversDate(row?.payment_dates[0]?.date)}

                  {c.id === 'status' ? (
                    row.status.id === 4 || user.department === 2 || user.department === 3 ? (
                      <StatusDropdown
                        id={row.id}
                        financeCost
                        status={user.department === 2 ? row?.financial_status?.id : row?.status.id}
                      />
                    ) : (
                      <StatusBtn
                        style={{ padding: '5px 14px' }}
                        financeCost
                        status={row?.status.id}
                      />
                    )
                  ) : null}

                </>
              )}

              {report && (
              <>
                {c.id === 'title'
                  && (
                    <>
                      <p
                        style={{ margin: 0 }}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      >
                        {row.project?.name}
                      </p>
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: 'none',
                        }}
                        open={row.project?.name?.length >= 33 ? open : false}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableAutoFocus
                        disableEnforceFocus
                      >
                        <div className={classes.popoverText}>{row.project?.name}</div>
                      </Popover>
                    </>
                  )}

                {c.id === 'period' && (
                <div className={classes.period__container}>
                  <div className={classes.period__container__inner}>
                    <img src={calendar} alt="calendar" />
                    <div>{reversDate(row?.date_start)}</div>
                  </div>

                  <div className={classes.period__container__inner}>
                    <img src={calendar} alt="calendar" />
                    <div>{reversDate(row?.date_end)}</div>
                  </div>
                </div>
                )}

                {c.id === 'payment_date' && (
                <div className={classes.paymentDate__container}>
                  {row?.payments?.map((payment_date) => (

                    <div key={payment_date.id} className={classes.paymentDate__container__inner}>
                      {payment_date?.date && <img src={calendar} alt="calendar" />}
                      <div>{payment_date?.date ? reversDate(payment_date?.date) : '-'}</div>
                    </div>

                  ))}
                </div>
                )}

                {c.id === 'payment_sum' && (
                <div className={classes.paymentSum__container}>
                  {row?.payments?.map((payment_date) => (

                    <div key={payment_date.id}>
                      <div>{payment_date.sum ? numberToSum(payment_date.sum) : '-'}</div>
                    </div>

                  ))}
                </div>
                )}

                {c.id === 'accounting_month' && (
                <div className={classes.paymentDate__container}>

                  <div className={classes.paymentDate__container__inner}>
                    <img src={calendar} alt="calendar" />
                    <div>{reversDate(row?.accounting_month)}</div>
                  </div>

                </div>
                )}

                {c.id === 'paid' && (row?.paid_sum ? numberToSum(row?.paid_sum) : 0)}

                {c.id === 'debt' && (row?.credit ? numberToSum(row?.credit) : 0)}

                {c.id === 'margin' && (row?.project?.margin ? numberToSum(row?.project.margin) : 0)}

                {c.id === 'project_status' && (
                <StatusBtn
                  status={row?.project?.status.id}
                  style={{ padding: '5px 15px' }}
                  marketing
                />
                )}
              </>
              )}

              {/* Основная строка */}
              {projectAccounting && (
              <>
                {c.id === 'project' && (
                <>
                  <p
                    style={{ margin: 0 }}
                    onMouseEnter={handlePopoverOpenProjectName}
                    onMouseLeave={handlePopoverCloseProjectName}
                  >
                    {row?.project?.name}
                  </p>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={row?.project?.name.length >= 33 ? openProjectName : false}
                    anchorEl={anchorElProject}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverCloseProjectName}
                    disableAutoFocus
                    disableEnforceFocus
                  >
                    <div className={classes.popoverText}>{row?.project?.name}</div>
                  </Popover>
                </>
                )}

                {c.id === 'client_legal_entity' && (
                <>
                  <p
                    style={{ margin: 0 }}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    {row?.client_legal_entity}
                  </p>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={row?.client_legal_entity?.length >= 20 ? open : false}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableAutoFocus
                    disableEnforceFocus
                  >
                    <div className={classes.popoverText}>{row?.client_legal_entity}</div>
                  </Popover>
                </>
                )}

                {c.id === 'contractor_legal_entity' && (
                <>
                  <p
                    style={{ margin: 0 }}
                    onMouseEnter={handlePopoverOpenAlt}
                    onMouseLeave={handlePopoverCloseAlt}
                  >
                    {row?.contractor_legal_entity}
                  </p>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: 'none',
                    }}
                    open={row?.contractor_legal_entity?.length >= 20 ? openAlt : false}
                    anchorEl={anchorElAlt}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverCloseAlt}
                    disableAutoFocus
                    disableEnforceFocus
                  >
                    <div className={classes.popoverText__alt}>{row?.contractor_legal_entity}</div>
                  </Popover>
                </>
                )}

                {c.id === 'realization_period' && (
                <div className={classes.period__container}>
                  <div className={classes.period__container__inner}>
                    <img src={calendar} alt="calendar" />
                    <div>{reversDate(row?.date_start)}</div>
                  </div>

                  <div className={classes.period__container__inner}>
                    <img src={calendar} alt="calendar" />
                    <div>{reversDate(row?.date_end)}</div>
                  </div>
                </div>
                )}

                {c.id === 'status' && (
                <StatusDropdown
                  row={row}
                  status={status}
                  setStatus={setStatus}
                  ProjectAccounting
                  mainRow={mainRow}
                  id={row?.id}
                  setComments={setComments}
                  setIndicateRed={setIndicateRed}
                  setQuarter={setQuarter}
                  closingInfo={closingInfo}
                />
                )}

                {c.id === 'g_l' && (
                  <>
                    <p
                      style={{ margin: 0 }}
                      onMouseEnter={handlePopoverOpenGL}
                      onMouseLeave={handlePopoverCloseGL}
                    >
                      {row?.type || '-'}
                    </p>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={row?.type?.length >= 8 ? openGL : false}
                      anchorEl={anchorGL}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverCloseGL}
                      disableAutoFocus
                      disableEnforceFocus
                    >
                      <div className={classes.popoverText__alt}>{row?.type}</div>
                    </Popover>
                  </>
                )}

                {c.id === 'sum' && (row?.total_sum ? numberToSum(row?.total_sum) : 0)}

                {c.id === 'tax' && (row?.nds_sum ? numberToSum(row?.nds_sum) : 0)}
                {c.id === 'payment_date' && (
                <div className={classes.paymentDate__container__alt}>
                  {paymentsDate?.map((payment_date) => (
                    <div key={payment_date.id} className={classes.paymentDate__container__inner__alt}>
                      <div>
                        {payment_date?.date && <img src={calendar} alt="calendar" />}&ensp;
                        {payment_date?.date ? reversDate(payment_date?.date) : '-'}
                      </div>
                      <div>{payment_date.sum ? numberToSum(payment_date.sum) : '-'}</div>
                    </div>
                  ))}
                </div>
                )}
                {c.id === 'adesk' && (
                  <span onClick={changeAdeskHandler}>
                    <CheckBox
                      className={classes.checkbox__project__accounting}
                      checked={isAdesk}
                      disabled
                    />
                  </span>
                )}
                {c.id === 'paid_sum' && (
                  <div className={classes.paymentDate__container__alt}>
                    {incomeDate ? incomeDate?.map((income_date) => (
                      <div key={income_date.id} className={classes.paymentDate__container__inner__alt}>
                        <div>
                          {income_date?.date && <img src={calendar} alt="calendar" />}&ensp;
                          {income_date?.date ? reversDate(income_date?.date) : null}
                        </div>
                        <div>{income_date.sum ? numberToSum(income_date.sum) : 0}</div>
                      </div>
                    )) : 0}
                  </div>
                )}
                {c.id === 'balance' && (row?.credit ? numberToSum(row?.credit) : 0)}
                {c.id === 'month_and_accounting_amount' && row?.closing_info?.length === 1 && (
                <div className={classes.paymentDate__container__alt}>
                  <div key={row?.closing_info[0].id} className={classes.paymentDate__container__inner__alt}>
                    <div>
                      {row?.closing_info[0]?.date && <img src={calendar} alt="calendar" />}&ensp;
                      {row?.closing_info[0]?.date ? reversDate(row?.closing_info[0]?.date) : '-'}
                    </div>
                    <div>{row?.closing_info[0].sum ? numberToSum(row?.closing_info[0].sum) : 0}</div>
                  </div>
                </div>
                )}
                {c.id === 'quarter' && row?.closing_info?.length === 1 && (
                  <QuarterDropdown
                    setComments={setComments}
                    setIndicateRed={setIndicateRed}
                    status={status}
                    row={row}
                    closingInfo={false}
                    initialQuarter={quarter}
                    // @ts-ignore
                    isAccepted
                  />
                )}
                {c.id === 'comment' && row?.closing_info?.length === 1 && (
                  <div className={classes.comment}>
                    <div className={classes.comment__container}>
                      <div className={classes.comment__container__comment}>
                        {comments && comments[0]}
                      </div>
                      {isExpanded ? (
                        comments?.slice(1).map((com, index) => (
                          <div key={index} className={classes.comment__container__comment__additional}>
                            {com}
                          </div>
                        ))
                      ) : null}
                      {numberOfAdditionalComments > 0 && (
                      <button
                        className={classes.comment__container__button}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsExpanded(!isExpanded);
                        }}
                      >
                        <div className={classes.comment__container__button__text}>
                          {isExpanded ? 'Скрыть изменения' : `+ Еще ${numberOfAdditionalComments} изменений`}
                        </div>
                      </button>
                      )}
                    </div>
                    {(user.user.role.id === 2 || user.user.department.id === 2 || user.user.department.id === 3) && (
                      indicateRed && (
                      <div className={classes.comment__container__icon}>
                        <div className={classes.comment__container__icon__icon} onClick={(e) => removeAllComments(e, row?.closing_info[0]?.id, false)} />
                      </div>
                      )
                    )}
                  </div>
                )}
              </>
              )}
              {/* Основная строка */}

              {/* Дополнительная строка */}
              {(additionalRow && row?.closing_info.length > 1) && (
                <>
                  {c.id === 'project' && ''}

                  {c.id === 'client_legal_entity' && (
                  <>
                    <p
                      style={{ margin: 0 }}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      {row?.client_legal_entity}
                    </p>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={row?.client_legal_entity?.length >= 20 ? open : false}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableAutoFocus
                      disableEnforceFocus
                    >
                      <div className={classes.popoverText}>{row?.client_legal_entity}</div>
                    </Popover>
                  </>
                  )}

                  {c.id === 'contractor_legal_entity' && (
                  <>
                    <p
                      style={{ margin: 0 }}
                      onMouseEnter={handlePopoverOpenAlt}
                      onMouseLeave={handlePopoverCloseAlt}
                    >
                      {row?.contractor_legal_entity}
                    </p>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={row?.contractor_legal_entity?.length >= 20 ? openAlt : false}
                      anchorEl={anchorElAlt}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverCloseAlt}
                      disableAutoFocus
                      disableEnforceFocus
                    >
                      <div className={classes.popoverText__alt}>{row?.contractor_legal_entity}</div>
                    </Popover>
                  </>
                  )}

                  {c.id === 'realization_period' && (
                  <div className={classes.period__container}>
                    <div className={classes.period__container__inner}>
                      <img src={calendar} alt="calendar" />
                      <div>{reversDate(row?.date_start)}</div>
                    </div>

                    <div className={classes.period__container__inner}>
                      <img src={calendar} alt="calendar" />
                      <div>{reversDate(row?.date_end)}</div>
                    </div>
                  </div>
                  )}

                  {c.id === 'status' && (
                  <StatusDropdown
                    row={row}
                    closingInfo={closingInfo}
                    status={status}
                    setStatus={setStatus}
                    ProjectAccounting
                    id={closingInfo.id}
                    additionalRow
                    setAdditionalRowComments={setAdditionalRowComments}
                    setAdditionalRowIndicateRed={setAdditionalRowIndicateRed}
                    setIndicateRed={setIndicateRed}
                    setQuarter={setQuarter}
                    setComments={setComments}
                  />
                  )}

                  {c.id === 'g_l' && (
                  <>
                    <p
                      style={{ margin: 0 }}
                      onMouseEnter={handlePopoverOpenGL}
                      onMouseLeave={handlePopoverCloseGL}
                    >
                      {row?.type || '-'}
                    </p>
                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: 'none',
                      }}
                      open={row?.type?.length >= 8 ? openGL : false}
                      anchorEl={anchorGL}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverCloseGL}
                      disableAutoFocus
                      disableEnforceFocus
                    >
                      <div className={classes.popoverText__alt}>{row?.type}</div>
                    </Popover>
                  </>
                  )}

                  {c.id === 'sum' && ''}

                  {c.id === 'tax' && ''}
                  {c.id === 'payment_date' && ''}
                  {c.id === 'adesk' && ''}
                  {c.id === 'paid_sum' && ''}
                  {c.id === 'balance' && ''}
                  {c.id === 'month_and_accounting_amount' && (
                  <div className={classes.paymentDate__container__alt}>
                    <div className={classes.paymentDate__container__inner__alt}>
                      <div>
                        {accountingDate?.date && <img src={calendar} alt="calendar" />}&ensp;
                        {accountingDate?.date ? reversDate(accountingDate?.date) : '-'}
                      </div>
                      <div>{accountingDate?.sum ? numberToSum(accountingDate?.sum) : 0}</div>
                    </div>
                  </div>
                  )}
                  {c.id === 'quarter' && (
                  <QuarterDropdown
                    setAdditionalRowComments={setAdditionalRowComments}
                    setAdditionalRowIndicateRed={setAdditionalRowIndicateRed}
                    status={status}
                    closingInfo={closingInfo}
                    row={row}
                    initialQuarter={quarter}
                    // @ts-ignore
                    isAccepted
                  />
                  )}
                  {c.id === 'comment' && (
                  <div className={classes.comment}>
                    <div className={classes.comment__container}>
                      <div className={classes.comment__container__comment}>
                        {additionalRowComments && additionalRowComments[0]}
                      </div>
                      {isAdditionalRowExpanded ? (
                        additionalRowComments?.slice(1).map((com, index) => (
                          <div key={index} className={classes.comment__container__comment__additional}>
                            {com}
                          </div>
                        ))
                      ) : null}
                      {numberOfAdditionalRowComments > 0 && (
                      <button className={classes.comment__container__button} type="button" onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsAdditionalRowExpanded(!isAdditionalRowExpanded); }}>
                        <div className={classes.comment__container__button__text}>
                          {isAdditionalRowExpanded ? 'Скрыть изменения' : `+ Еще ${numberOfAdditionalRowComments} изменений`}
                        </div>
                      </button>
                      )}
                    </div>
                    {(user.user.role.id === 2 || user.user.department.id === 2 || user.user.department.id === 3) && (
                      additionalRowindicateRed && (
                      <div className={classes.comment__container__icon}>
                        <div className={classes.comment__container__icon__icon} onClick={(e) => removeAllComments(e, closingInfo.id, true)} />
                      </div>
                      )
                    )}
                  </div>
                  )}
                </>
              )}
              {/* Дополнительная строка */}
            </div>
          ))}
      </Link>
    </div>
  );
}

export default RegistryTableRow;
